<template>
  <CoreDialog
      :title="title"
      :loading="false"
      :dialog-props="dialogProps">
    <template slot="content">
      <CreateOrderForm
          :dialog-props="dialogProps" />
    </template>
  </CoreDialog>
</template>

<script>
  import CreateOrderForm from "../../forms/CreateOrderForm/CreateOrderForm";

  export default {
    name: "CreateOrderDialog",
    components: {
      CreateOrderForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      },
      title() {
       return "Create New Order"
      }
    },
  }
</script>