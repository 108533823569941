import FeedbackManagement from "./FeedbackManagement"
import FeedbackManagementIcon from "./components/FeedbackManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const FeedbackManagementModule = {
  moduleGrouping: "Content Management",
  name: "MyFeedback",
  menuName: "Feedback & Reviews",
  component: FeedbackManagement,
  path: "FeedbackManagement",
  icon: FeedbackManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View FeedbackManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("FeedbackManagement", FeedbackManagement)
    Vue.component("FeedbackManagementIcon", FeedbackManagementIcon)
  }
}
export default FeedbackManagementModule
