import CaneToadProductCategoryColumn from "./CaneToadProductCategoryColumn"
import ProductThumbnailColumn from "./ProductThumbnailColumn"
import ProductQuantityColumn from "./ProductQuantityColumn"
import ProductStatusColumn from "./CaneToadProductStatusColumn"
import CaneToadProductCarModelsColumn
  from "./CaneToadProductCarModelsColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "SKU",
      label: "SKU"
    },
  {
    prop: 'productThumbnail',
    label: 'Thumbnail',
    Component: ProductThumbnailColumn
  },
  {
    prop: "Name",
    label: "Name",
  },
  {
    prop: "Subtitle",
    label: "Subtitle",
  },
  {
    prop: "CarModels",
    label: "Car Models",
    Component: CaneToadProductCarModelsColumn
  },
  {
    prop: "categoryTitle",
    label: "Category",
    Component: CaneToadProductCategoryColumn
  },
  {
    prop: 'SellPrice',
    label: 'Sell Price',
  },
  {
    label: "Stock at Hand",
    Component: ProductQuantityColumn
  },
  {
    label: "Status",
    Component: ProductStatusColumn
  }
]

export default defaultColumns