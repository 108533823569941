<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps">
    <template slot="content">
      <ElAlert
          type="info"
          title="This form lets you an send an order confirmation to the customer." />
      <OrderEmailForm
          :dialog-props="dialogProps"
          :order-data="order" />
    </template>
  </CoreDialog>
</template>

<script>
  import { Order } from "@/Modules/Models"
  import OrderEmailForm from "../../forms/OrderEmailForm/OrderEmailForm";

  export default {
    name: "OrderEmailDialog",
    components: {
      OrderEmailForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true,
        order: {}
      }
    },
    computed: {
      orderData() {
        return Order.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.order) {
          const orderDate = new Date(this.order.DatePlaced).toLocaleDateString()
          return `Send Order Confirmation: ${this.order.BillFirstName} ${this.order.BillLastName} | ${orderDate} | Order ID: ${this.order.OrderID}`
        }
        return "View Order"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchOrder()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchOrder() {
        this.isLoading = true

        // await Order.api().fetchById(this.id)
        const response =  await Order.api().getOrder(this.id)
        if (response.response.status === 200) {
          const tempOrder = response.response.data.data.data
          tempOrder.emailTo = tempOrder.Email
          tempOrder.fullName = `${tempOrder.BillFirstName} ${tempOrder.BillLastName}`
          tempOrder.emailSubject = `Canetoad Equip Order Confirmation - ${tempOrder.OrderID}`
          tempOrder.emailFrom = `sales@canetoad.com.au`
          this.order = tempOrder
        }
        this.isLoading = false
      }
    }
  }
</script>