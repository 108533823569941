<template>
  <div class="sales-overview-container">
    <div class="flex-container">
      <el-card class="card">
        <template #header>
          <h4>Total Sales</h4>
        </template>
        <h3>{{ totalSales }} AUD</h3>
      </el-card>

      <el-card class="card">
        <template #header>
          <h4>Total Orders</h4>
        </template>
        <h3>{{ uniqueOrders }}</h3>
      </el-card>

      <el-card class="card">
        <template #header>
          <h4>Unique Customers</h4>
        </template>
        <h3>{{ uniqueCustomers }}</h3>
      </el-card>

      <el-card class="card">
        <template #header>
          <h4>Total Products</h4>
        </template>
        <h3>{{ totalProducts }}</h3>
      </el-card>

      <el-card class="card">
        <template #header>
          <h4>Reviewed Products</h4>
        </template>
        <h3>{{ totalProductsReviewed }}</h3>
      </el-card>
    </div>
    <div class="sales-graph">
      <el-card>
        <template #header>
          <div class="sales-chart-header">
            <h3>Weekly Sales Orders (~{{ formattedWeeklySalesAverage }} / week)</h3>
            <el-button
                type="primary"
                @click="updateWeeklyChart">
              Refresh
            </el-button>
          </div>
        </template>
        <VueApexCharts
            width="100%"
            height="350"
            type="line"
            :options="chartOptions"
            :series="series" />
      </el-card>
    </div>

    <h3>Last 5 Months Sales:</h3>

    <div class="flex-container">
      <el-card
          v-for="month in lastFiveMonthsSales"
          :key="month.month"
          class="card">
        <template #header>
          <h4>{{ month.month }}</h4>
        </template>
        <h3>{{ formatCurrency(month.sales) }}</h3>
      </el-card>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import axios from "axios";
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "SalesOverview",
  components: {
    VueApexCharts
  },
  data() {
    return {
      totalSales: 0,
      totalOrders: 0,
      uniqueOrders: 0,
      totalProducts: 0,
      totalProductsReviewed: 0,
      uniqueCustomers: 0,
      paidOrders: [],
      chartOptions: {
        chart: {
          id: 'orders-chart',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          curve: 'stepline',
          width: 3
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Week'
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return "$" + value.toFixed(2)
            }
          }
        },
        tooltip: {
          x: {
            format: 'dd MMM yyyy'
          },
          y: {
            formatter: function(value) {
              return "$" + value.toFixed(2)
            }
          }
        },
        colors: ['#008FFB'],
        markers: {
          size: 4,
          colors: ["#008FFB"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          }
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },
      series: [{
        name: 'Weekly Sales',
        data: []
      }],
      weeklySalesData: []
    }
  },
  computed: {
    endpoints() {
      const baseURL = config.apiConfig.lightwerk.api
      const endpoint = "/statistics"
      const overviewEndpoint = `${baseURL}${endpoint}/getOverview`
      const paidOrdersEndpoint = `${baseURL}${endpoint}/getPaidOrders`

      const endpoints = {
        getStatisticsOverview: overviewEndpoint,
        getPaidOrders: paidOrdersEndpoint
      }

      return endpoints
    },
    weeklySalesAverage() {
      if (this.weeklySalesData.length === 0) return 0;
      const total = this.weeklySalesData.reduce((sum, week) => sum + week.total, 0);
      return total / this.weeklySalesData.length;
    },
    formattedWeeklySalesAverage() {
      return `$ ${this.weeklySalesAverage.toFixed(2)} AUD`;
    },
    lastFiveMonthsSales() {
      const monthlyData = this.calculateMonthlySales(this.weeklySalesData);
      return monthlyData.slice(-5);  // Get the last 3 months
    }
  },
  mounted() {
    this.getOverview()
    this.updateWeeklyChart()
  },
  methods: {
    formatCurrency(amount, currency = 'USD', locale = 'en-US') {
      return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount);
    },
    async getOverview() {
      const response = await axios.get(this.endpoints.getStatisticsOverview)
      const payload = response.data.data

      this.totalSales = this.formatCurrency(payload.totalSales)
      // format with money format
      this.totalSales = this.totalSales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      this.uniqueOrders = payload.uniqueOrders
      this.totalOrders = payload.totalOrders
      this.totalOrders = this.totalOrders.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.totalProducts = payload.totalProducts
      this.totalProductsReviewed = payload.totalProductsReviewed
      this.uniqueCustomers = payload.uniqueCustomers
    },
    async updateWeeklyChart() {
      const response = await axios.get(this.endpoints.getPaidOrders)

      this.paidOrders = response.data.data

      const weeklySales = this.aggregateWeeklySales(this.paidOrders);
      this.weeklySalesData = weeklySales;
      this.series = [{
        name: 'Weekly Sales',
        data: weeklySales.map(week => ({
          x: week.weekStart,
          y: week.total
        }))
      }]
    },
    aggregateWeeklySales(orders) {
      const weeklySales = {};
      orders.forEach(order => {
        const date = new Date(order.paymentDate);
        const weekStart = this.getWeekStart(date);
        const weekKey = weekStart.toISOString();
        if (!weeklySales[weekKey]) {
          weeklySales[weekKey] = { weekStart, total: 0 };
        }
        weeklySales[weekKey].total += parseFloat(order.totalPricePaid);
      });
      return Object.values(weeklySales).sort((a, b) => a.weekStart - b.weekStart);
    },
    getWeekStart(date) {
      const d = new Date(date);
      d.setDate(d.getDate() - d.getDay()); // Set to Sunday
      d.setHours(0, 0, 0, 0);
      return d;
    },
    calculateMonthlySales(weeklySalesData) {
      const monthlySales = {};
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];

      weeklySalesData.forEach(week => {
        const date = new Date(week.weekStart);
        const monthKey = `${date.getFullYear()}-${date.getMonth()}`;
        if (!monthlySales[monthKey]) {
          monthlySales[monthKey] = {
            month: `${monthNames[date.getMonth()]} ${date.getFullYear()}`,
            sales: 0,
            date: date
          };
        }
        monthlySales[monthKey].sales += week.total;
      });

      return Object.values(monthlySales)
          .sort((a, b) => a.date - b.date);
    },
  }
}
</script>

<style lang="scss" scoped>
.sales-overview-container {
  padding: 1rem;
  h4 {
    margin: 0.25rem 0.5rem;
    padding: 0;
    font-size: 1rem;
  }
  h3 {
    text-align: center;
    font-size: 1.35rem;
  }
}
.card {
  width: 20%;
  color: white;

  :deep(.el-card__header) {
    border: none;
    background: rgba(0,0,0,0.2);

  }
  :deep(.el-card__body) {
  }

  &:first-child {
    background: #007cff;
  }
  &:nth-child(2) {
    background: #00d68f;
  }
  &:nth-child(3) {
    background: #ff9900;
  }
  &:nth-child(4) {
    background: #ff5722;
  }
  &:nth-child(5) {
    background: #ff2d55;
  }
}
.flex-container {
  display: flex;
  gap: 1rem;
}
.sales-graph {
  margin: 2rem 0.5rem;

  .sales-chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
