<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <BlogManagementForm
          :dialog-props="dialogProps"
          :blog-data="blogData" />
    </template>
  </CoreDialog>
</template>

<script>
  import BlogManagementForm from "../../forms/BlogManagementForm/BlogManagementForm"
  import { Blog } from "@/Modules/Models"
  import startCase from "lodash/startCase"

  export default {
    name: "BlogManagementDialog",
    components: {
      BlogManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      blogData() {
        return Blog.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.blogData) {
          return `${startCase(this.mode)}ing BlogManagement ${this.blogData.fullName} (ID: ${this.id})`
        }

        return "Create New BlogManagement"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchBlog()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchBlog() {
        this.isLoading = true

        await Blog.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>