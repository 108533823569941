import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    models: null,
    makes: null,
    trims: null,
    years: null,
    title: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition