<template>
  <div class="flex tag-column">
    <el-tag
        v-for="(carModel, index) in carModelsList"
        :key="index"
        type="info"
        size="mini">
      {{ carModel.title }}
    </el-tag>
  </div>
</template>
<script>
  export default {
    name: "CaneToadProductCategoryColorColumn",
    props: {
      scope: {
        type: Object,
        required: true
      }
    },
    computed: {
      carModelsList() {
        return this.$store.getters["CaneToadProductManagement/getModelList"](this.scope.row.CarModels)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tag-column {
   display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

  }
</style>
