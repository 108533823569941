<template>
  <div class="shopping-cart-container">
    <template v-if="Object.keys(selectedProducts).length > 0">
      <div>
        <table class="item-table">
          <thead>
            <tr>
              <th width="10%">
                SKU
              </th>
              <th width="20%">
                Title
              </th>
              <th width="10%">
                Price
              </th>
              <th width="10%">
                Quantity
              </th>
              <th width="10%">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="(item, productId) in selectedProducts"
                :key="productId">
              <td>{{ item.product.SKU }}</td>
              <td>{{ item.product.Name }}</td>
              <td>{{ item.product.SellPrice }}</td>
              <td>
                <el-input-number
                    v-model="item.quantity"
                    size="mini"
                    :min="1"
                    @change="updateQuantity(productId, $event)" />
              </td>
              <td>
                <el-button
                    size="mini"
                    @click="removeFromCart(productId)">
                  Remove
                </el-button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                  colspan="4"
                  class="text-right">
                <strong>Cart Total:</strong>
              </td>
              <td colspan="2">
                <strong>{{ formatPrice(cartTotal) }}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
    <template v-else>
      <El-Empty description="Add products from the table below" />
    </template>
    <el-divider />
    <core-data-table-v2
        :model="productModel"
        :columns="columns"
        :searches-and-filters="filters"
        :show-actions-column="false"
        :show-bulk-actions-column="false"
        table-name="shopping-cart-table"
        v-on="$listeners"
        @onAddToCartClick="onAddToCartClick" />
  </div>
</template>

<script>
import Vue from 'vue'
import filterConfig from "@/Modules/CaneToadProductManagement/components/CaneToadProductManagementTableView/filterConfig"
import { CaneToadProduct } from "@/Modules/Models"
import { CarModels } from "@/Modules/Models"
import columns from "./components/shoppingCart/columns";

export default {
  name: "ShoppingCart",
  data() {
    return {
      isLoading: false,
      filters: filterConfig,
      selectedProducts: {}
    }
  },
  computed: {
    productModel() {
      return CaneToadProduct
    },
    columns() {
      return columns
    },
    cartTotal() {
      return Object.values(this.selectedProducts).reduce((total, item) => {
        return total + this.calculateItemTotal(item);
      }, 0);
    }
  },
  watch: {
    selectedProducts: {
      handler: function () {
        const payload = {
          products: this.selectedProducts,
          total: this.cartTotal
        }
        this.$emit('input', payload);
      },
      deep: true
    }
  },
  mounted() {
    this.getCarModels()
  },
  methods: {
    onAddToCartClick(product) {
      if (this.selectedProducts.hasOwnProperty(product.id)) {
        Vue.set(this.selectedProducts[product.id], 'quantity', this.selectedProducts[product.id].quantity + 1);
      } else {
        Vue.set(this.selectedProducts, product.id, { product, quantity: 1 });
      }
    },
    removeFromCart(productId) {
      Vue.delete(this.selectedProducts, productId);
    },
    updateQuantity(productId, newQuantity) {
      if (newQuantity > 0) {
        Vue.set(this.selectedProducts[productId], 'quantity', newQuantity);
      } else {
        this.removeFromCart(productId);
      }
    },
    calculateItemTotal(item) {
      return parseFloat(item.product.SellPrice) * item.quantity;
    },
    formatPrice(price) {
      return `$${price.toFixed(2)}`;
    },
    async getCarModels() {
      this.isLoading = true;
      const response = await CarModels.api().fetchAll();
      this.$store.dispatch("CaneToadProductManagement/updateCarModels", response.response.data.data);
      this.isLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.shopping-cart-container {
  margin-bottom: 20px;
}

.item-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid var(--main-border-color);

  thead {
    background: var(--main-light-grey);
  }

  th, td {
    padding: 0.5em;
    border: 1px solid var(--main-border-color);
    line-height: 1.2;
  }

  tbody {
    tr:nth-child(even) {
      background: var(--main-light-grey);
    }
  }
}
</style>