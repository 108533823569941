<template>
  <div class="payment-type-column">
    <template v-if="scope.row.paymentType">
      <template v-if="scope.row.limepayError">
        <el-popover
            placement="right"
            width="400"
            trigger="click">
          <code>{{ scope.row.limepayError }}</code>

          <el-badge
              slot="reference"
              value="i"
              class="item">
            {{ scope.row.paymentType | spaceUnderscore }} &nbsp;
          </el-badge>
        </el-popover>
      </template>
    </template>
    <template v-else>
      {{ scope.row.paymentType | spaceUnderscore }}
    </template>
  </div>  
</template>
<script>
export default {
  name: "OrderManagementPaymentTypeColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ").toUpperCase()
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    }
  }
}
</script>