<template>
  <div class="product-quantity-column-container">
    <el-tag
        :type="getQuantityStatusType(scope.row.AvailableSellQuantity)"
        disable-transitions>
      {{ scope.row.AvailableSellQuantity }}
    </el-tag>
  </div>
</template>

<script>
export default {
  name: "ProductQuantityColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  methods: {
    getQuantityStatusType(quantity) {
      if (quantity > 5) {
        return "success"
      } else if (quantity > 0) {
        return "warning"
      } else {
        return "danger"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>