<template>
  <div class="global-search-dropdown">
    <div class="header">
      <core-text
          bold
          capitalize>
        {{ totalDataCount }} {{ pluralEntity }} found
      </core-text>
    </div>

    <core-divider />
    <div class="body flex-column">
      <template v-if="loading">
        <div class="flex-center flex-auto loading-container">
          <i class="el-icon-loading" />
          <core-text
              tag="h3"
              label>
            loading...
          </core-text>
        </div>
      </template>


      <template v-else-if="searchResults.length">
        <CoreGlobalSearchEntityList
            v-for="(results, index) in searchResults"
            :key="`search-${index}-${results.type}`"
            v-bind="results"
            :search-query="searchQuery"
            :store="store"
            :show-divider="showDivider(index)" />
      </template>

      <template v-else-if="showRecentSearches">
        <div class="flex-auto recent-searches">
          <core-text
              tag="h3"
              class="header"
              label>
            Recent Searches
          </core-text>

          <core-text
              v-for="(recentSearch, index) in recentSearches"
              :key="recentSearch"
              semibold
              class="recent-search"
              label>
            <span
                class="recent-search-text flex-auto"
                @click="searchWithRecentSearch(recentSearch)"><i
                    class="el-icon-time" />{{ recentSearch }}</span>
            <span
                class="close-icon flex-center"
                @click="removeRecentSearch(index)"><i class="el-icon-close" /></span>
          </core-text>
        </div>
      </template>

      <template v-else-if="showShortQueryState">
        <div class="flex-center flex-auto">
          <core-text
              tag="h3"
              label>
            Please type more characters to start the search.
          </core-text>
        </div>
      </template>

      <template v-else-if="showInitialState">
        <div class="flex-auto flex-center">
          <core-text
              tag="h3"
              label>
            Start typing to search.
          </core-text>
        </div>
      </template>

      <template v-else>
        <div class="flex-center flex-auto">
          <core-text
              tag="h3"
              label>
            No matching records found.
          </core-text>
        </div>
      </template>

      <core-divider />

      <div class="footer justify-end">
        <el-pagination
            v-if="totalDataCount > pageSize"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            :layout="paginationLayout"
            :total="totalDataCount"
            :pager-count="pagerCount"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import Models from "@/Modules/Models"
import CoreGlobalSearchEntityList from "./CoreGlobalSearchEntityList"
import config from "@/config";
import debounce from "lodash/debounce"
import startCase from "lodash/startCase"
import snakeCase from "lodash/snakeCase"
import get from "lodash/get"
import axios from "axios"
import pascalCase from "pascalcase"
import camelCase from "lodash/camelCase"
import pluralize from "pluralize"

const LS_RECENT_SEARCHES = "global-search-recent"

export default {
  name: "CoreGlobalSearch",
  components: { CoreGlobalSearchEntityList },
  props: {
    searchQuery: { type: String, default: "" },
    selectedEntity: { type: String, default: "" },
    store: { type: Object, required: true }
  },
  data() {
    return {
      loading: false,
      searchResults: [],
      recentSearches: [],
      pageSize: 10,
      pagerCount: 5,
      paginationLayout: "slot, prev, pager, next, jumper",
      totalDataCount: 0,
      currentPage: 1
    }
  },
  computed: {
    pluralEntity() {
      return pluralize(startCase(this.selectedEntity))
    },
    queryTooShort() {
      return this.searchQuery.length < 3
    },
    showShortQueryState() {
      return this.queryTooShort
    },
    showRecentSearches() {
      return !this.searchQuery && this.recentSearches.length
    },
    showInitialState() {
      return !this.searchQuery
    },
    searchableEntities() {
      return Object.values(Models).filter(model => {
        return model.globalSearchEnabled
      })
    },
    entityTypes() {
      return this.searchableEntities.map(({ entity }) => ({
        label: pluralize(startCase(entity)),
        value: snakeCase(pluralize.singular(entity))
      }))
    }
  },

  watch: {
    searchQuery() {
      if (!this.queryTooShort) {
        this.loading = true
      }

      this.debouncedFetchSearchResults()
    },
    selectedEntity() {
      this.loading = true

      this.fetchSearchResults()
    },
    currentPage() {
      this.loading = true

      this.fetchSearchResults()
    }
  },
  mounted() {
    this.debouncedFetchSearchResults = debounce(this.fetchSearchResults, 500)
    this.recentSearches = JSON.parse(localStorage.getItem(LS_RECENT_SEARCHES)) || []
  },
  methods: {
    handleSizeChange(newPageSize) {
      this.pageSize = newPageSize
    },
    handleCurrentChange(nextPage) {
      this.currentPage = nextPage
    },
    showDivider(searchResultIndex) {
      return searchResultIndex !== this.searchResults.length - 1
    },
    searchWithRecentSearch(recentSearchQuery) {
      const [entity, searchQuery] = recentSearchQuery.split(":")

      this.$emit("setSearchQuery", searchQuery)
      this.$emit("setSelectedEntity", entity)
    },
    removeRecentSearch(index) {
      this.recentSearches.splice(index, 1)
      this.setRecentSearches()
    },
    getMatchingSearchTerms(record) {
      const { searchQuery } = this

      let matchingSearchTerms = ""

      Object.entries(record).forEach(([key, value]) => {
        if (`${value}`.toLowerCase().includes(searchQuery))
          matchingSearchTerms += `${startCase(key)}: ${value}, `
      })

      return matchingSearchTerms
    },
    setRecentSearches() {
      localStorage.setItem(LS_RECENT_SEARCHES, JSON.stringify(this.recentSearches))
    },
    handleRecentSearches() {
      const { recentSearches, searchQuery, setRecentSearches, selectedEntity } = this

      const search = `${selectedEntity}:${searchQuery}`

      if (recentSearches.includes(search)) {
        return
      }

      if (recentSearches.length < 5) {
        recentSearches.unshift(search)
      } else {
        recentSearches.unshift(search)
        recentSearches.pop()
      }

      setRecentSearches()
    },
    async fetchSearchResults() {
      const { searchQuery, handleRecentSearches, selectedEntity, pageSize, currentPage } = this
      const baseURL = config.apiConfig.lightwerk.api

      if (!this.queryTooShort) {
        const { data: response } = await axios.get(`${baseURL}/${pluralize(camelCase(selectedEntity))}/search`, {
          params: {
            query: searchQuery,
            per_page: pageSize,
            page: currentPage
          }
        })

        handleRecentSearches()

        const { data, meta } = response.data
        const searchResults = []

        const fieldKey = pluralize(camelCase(selectedEntity))

        this.totalDataCount = parseInt(get(meta, `${fieldKey}.total_count`))

        Object.keys(data).forEach(key => {
          if (key !== "id" && data[key]) {
            const type = selectedEntity
            const dataIds = [data[key].id]
            const label = pluralize(startCase(type))
            const model = Models[pascalCase(type)]
            model.insertOrUpdate({ data: data[key] })

            searchResults.push({ type, label, dataIds: dataIds, meta: meta[fieldKey], model })
          }
        })

        this.searchResults = searchResults
      } else {
        this.searchResults = []
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.global-search-dropdown {
  width: 600px;
  max-height: 70vh;
  overflow: auto;

  .recent-searches {
    .header {
      padding-bottom: var(--padding-s);
    }

    .recent-search {
      display: flex;
      width: 100%;
      cursor: pointer;

      .recent-search-text {
        padding: var(--padding-xs) var(--padding-m);
        display: flex;
        align-items: center;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }

        i {
          padding-right: var(--padding-xs);
        }
      }

      .close-icon {
        margin-left: auto;
        padding: 0 var(--padding-xs);
      }
    }
  }

  .header {
    padding: var(--padding-m);
    padding-bottom: 0;
    display: flex;
    align-items: center;
  }

  .footer {
    padding: 0 var(--padding-m);
    display: flex;
    align-items: center;
  }

  .body {
    padding-bottom: var(--padding-m);
    min-height: 5rem;

    ::v-deep  .entity-divider {
      padding: var(--padding-m) var(--padding-l);
      opacity: 0.5;
    }

    .loading-container {
      i {
        margin-right: var(--margin-m);
      }
    }
  }
}
</style>
