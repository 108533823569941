<template>
  <fragment>
    <CoreScreen
        card
        class="user-management-wrapper"
        header="User Management"
        icon="UserManagementIcon"
        :create-button="{ label: 'Create User', onCreate: createUser }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { User } from "@/Modules/Models"
export default {
  name: "UserManagement",

  methods: {
    createUser() {
     new User().Actions.openUserDialog()
    }
  }
}
</script>
