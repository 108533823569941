<template>
  <div
      ref="uploadContainer"
      class="multi-image-upload-container">
    <el-upload
        multiple
        :file-list="fileList"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        action="https://jsonplaceholder.typicode.com/posts/"
        drag>
      <i class="el-icon-upload" />
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
      <div
          slot="tip"
          class="el-upload__tip">
        jpg/png files with a size less than 500kb
      </div>
    </el-upload>
    <pre>{{ fileList }}</pre>
    <div
        v-for="(option, index) in options"
        :key="`upload-option-${index}`"
        v-loading="isUploading[index]"
        class="option-container"
        :class="{ 'option-container--active': uploadEnabledOptionValue === index }"
        @mouseenter="toggleUploadEnabled(index)">
      <el-checkbox
          v-model="enabledOptions[option.value]"
          :label="option.value">
        {{ option.description }}
      </el-checkbox>

      <div
          v-if="enabledOptions[option.value] || (value && value[index])"
          :key="imageSrcList[index]"
          class="avatar-uploader">
        <img
            v-if="imageSrcList[index] || (value && value[index])"
            :src="getImageSrc(index)"
            class="
            avatar">
        <el-button
            v-if="imageSrcList[index] || (value && value[index])"
            size="small"
            type="danger"
            @click.prevent="handleRemove(index)">
          Remove Image
        </el-button>
        <div
            v-else
            class="hint">
          Press
          <br>
          <kbd>CTRL</kbd> + <kbd>V</kbd>
          <br>
          To insert an image
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Upload } from "@/Modules/Models"

export default {
  name: "MultiImageUpload",
  props: {
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      imageBlog: null,
      imageUrl: 'test123',
      enabledOptions: [],
      imageSrcList: [],
      imageFilenameList: [],
      fileList: [],
      uploadEnabledOptionValue: null,
      isUploading: [],
    };
  },
  watch: {
    imageFilenameList: {
      handler() {
        this.$emit('input', this.imageFilenameList)
      },
      deep: true
    }
  },
  async mounted() {
    const { updateImageSrc, updateIsUploading, updateImageFilenameList, $store } = this
    this.$refs.uploadContainer.onpaste = function (event) {

      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let index in items) {

        updateIsUploading(true)

        let item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();

          const reader = new FileReader();
          reader.onload = async function (event) {
            const result = event.target.result
            if (result) {
              const fileUploadData = {
                name: blob.name,
                type: blob.type,
                size: blob.size,
                imageBase64: result
              }

              const uploadResult = await Upload.api().uploadImage(fileUploadData);

              if (uploadResult.response.data.error) {
                const notificationsPayload = {
                  component: "Toast",
                  type: "error",
                  title: "File Upload Error",
                  message: `Your image couldn't be uploaded. ${uploadResult.response.data.error}`,
                  visible: true,
                  decay: 3500
                }

                $store.dispatch("core/notifications/addToNotifications", notificationsPayload)

                updateIsUploading(false)
              } else {
                const fileName = uploadResult.response.data.data.data.fileName


                if (fileName) {
                  updateImageSrc(result)
                  updateImageFilenameList(fileName)
                  updateIsUploading(false)

                  const notificationsPayload = {
                    component: "Toast",
                    type: "success",
                    title: "File Upload successful",
                    message: `Your image upload was successful. `,
                    visible: true,
                    decay: 3500
                  }

                  $store.dispatch("core/notifications/addToNotifications", notificationsPayload)
                }
              }
            }
          };
          reader.readAsDataURL(blob);
        }
      }
    };
  },


  methods: {
    getImageSrc(index) {
      if (this.value[index] !== null) {
        return `https://api.otocarenet.com.au/media/${this.value[index]}`
      }
      return this.imageSrcList[index]
    },
    toggleUploadEnabled(index) {
      // check if any uploads are in progress
      const isUploading = Object.values(this.isUploading).some(value => value === true)
      if (!isUploading) {
        this.uploadEnabledOptionValue = index
      }
    },
    updateImageSrc(value) {
      const imageSrcList = JSON.parse(JSON.stringify(this.imageSrcList))
      imageSrcList[this.uploadEnabledOptionValue] = value
      this.imageSrcList = imageSrcList
    },
    updateImageFilenameList(value) {
      const index = this.uploadEnabledOptionValue
      const imageFilenameList = JSON.parse(JSON.stringify(this.imageFilenameList))
      imageFilenameList[index] = value
      this.imageFilenameList = imageFilenameList
    },
    updateIsUploading(value) {
      const index = this.uploadEnabledOptionValue
      this.isUploading = Object.assign({}, this.isUploading, { [index]: value })
    },
    handleRemove(index) {
      this.imageSrcList[index] = null
      this.value[index] = null
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-image-upload-container {

  .option-container {
    padding: var(--margin-xs) var(--padding-l);
    border-radius: var(--border-radius-s);
    background: var(--color-bridj-light-background);

  }
  .option-container--active {
    background: #d9edff;
  }

  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #FFF;
    width: 178px;
    height: 178px;
    margin-bottom: var(--margin-m);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    word-break: keep-all;

    .hint {
      text-align: center;
    }

    button {
      position: absolute;
      left: 50%;
      margin-left: -30%;
      bottom: 20px;
    }
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>