import autobind from "auto-bind"
import get from "lodash/get"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openOrderDialog(options)
  }

  openOrderDialog(options = {}) {
    const type = "OrderDialog"
    const { mode = "view" } = options
    const { orderID, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode,  id:orderID})
  }

  createOrderDialog(options = {}) {
    const type = "CreateOrderDialog"
    const { mode = "create" } = options
    const { store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode })
  }

  openOrderEmailDialog(options = {}) {
    const type = "OrderEmailDialog"
    const { mode = "edit" } = options
    const { orderID, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode,  id:orderID})
  }

  openInvoiceEmailDialog(options = {}) {
    const type = "InvoiceEmailDialog"
    const { mode = "edit" } = options
    const { orderID, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode,  id:orderID})
  }

  async checkPaymentStatus() {
    const { orderID } = this.entity
    await this.entity.Api.checkPaymentStatus(orderID)
  }

  async createInvoicePDF() {
    const { store, orderID, username } = this.entity
    const response = await this.entity.Api.createInvoicePDF(orderID)
    const invoiceTitle = `Order: ${orderID} | Username ${username}`

    const fileLocation = get(response.response.data,"fileLocation")
    let notificationsPayload

    if (fileLocation) {
      notificationsPayload = {
        component: "Toast",
        type: "download",
        title: "Thank you",
        position: "top-right",
        message: `Your PDF has been successfully created and can be downloaded from here: <a href="${fileLocation}" target="_blank">Invoice (${invoiceTitle})</a>`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error creating PDF",
        message: `Sorry, your PDF could not be created.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)

  }


  get actionsList() {

    return [
      {
        visible: true,
        label: "View Order",
        icon: "el-icon-view",
        onClick: () => this.openOrderDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Order",
        icon: "el-icon-edit",
        onClick: () => this.openOrderDialog({ mode: "edit" })
      },
      {
        visible: true,
        label: "Refresh Payment Status",
        icon: "el-icon-refresh",
        onClick: () => this.checkPaymentStatus()
      },
      {
        visible: true,
        label: "Send Order",
        icon: "el-icon-sell",
        onClick: () => this.openOrderEmailDialog()
      },
      {
        visible: true,
        label: "Send Invoice",
        icon: "el-icon-document",
        onClick: () => this.openInvoiceEmailDialog()
      },
      {
        visible: true,
        divided: true,
        label: "Download Invoice as PDF",
        icon: "el-icon-printer",
        onClick: () => this.createInvoicePDF()
      },
      // {
      //   visible: true,
      //   label: `${capitalize(statusChangeText)} Order`,
      //   icon: isApproved ? "el-icon-close" : "el-icon-check",
      //   onConfirm: () =>
      //       self.Api.update({ id: self.id, status: isApproved ? "deactivated" : "activated" }),
      //   confirmProps: {
      //     title: `Are you sure you want to ${statusChangeText} this Order?`,
      //     confirmButtonText: `${capitalize(statusChangeText)} Order`
      //   }
      // },
      // {
      //   visible: true,
      //   divided: true,
      //   class: "danger",
      //   label: "Delete",
      //   icon: "el-icon-delete",
      //   onConfirm: () => self.Api.destroy(self),
      //   confirmProps: {
      //     title: "Are you sure you want to delete this Order?",
      //     confirmButtonText: "Delete Order"
      //   }
      // }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
