import CaneToadProductManagementDialog from "./CaneToadProductManagementDialog/CaneToadProductManagementDialog"
import CaneToadProductImportDialog
  from "./CaneToadProductImportDialog/CaneToadProductImportDialog";
export default [
  {
    name: "CaneToadProductDialog",
    Component: CaneToadProductManagementDialog,
    permissions: "CaneToadProductManagement:CaneToadProductManagement:canRead"
  },
  {
    name: "CaneToadProductImportDialog",
    Component: CaneToadProductImportDialog,
    permissions: "CaneToadProductManagement:CaneToadProductManagement:canRead"
  }
]