import { render, staticRenderFns } from "./AlertWidget.vue?vue&type=template&id=68995071&scoped=true"
import script from "./AlertWidget.vue?vue&type=script&lang=js"
export * from "./AlertWidget.vue?vue&type=script&lang=js"
import style0 from "./AlertWidget.vue?vue&type=style&index=0&id=68995071&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68995071",
  null
  
)

export default component.exports