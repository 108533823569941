<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps">
    <template slot="content">
      <OrderManagementForm
          :dialog-props="dialogProps"
          :order-data="order" />
    </template>
  </CoreDialog>
</template>

<script>
  import { Order } from "@/Modules/Models"
  import OrderManagementForm from "../../forms/OrderManagementForm/OrderManagementForm";

  export default {
    name: "OrderManagementDialog",
    components: {
      OrderManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true,
        order: {}
      }
    },
    computed: {
      orderData() {
        return Order.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.order) {
          const orderDate = new Date(this.order.DatePlaced).toLocaleDateString()
          return `${this.order.BillFirstName} ${this.order.BillLastName} | ${orderDate} | Order ID: ${this.order.OrderID}`
        }
        return "View Order"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchOrder()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchOrder() {
        this.isLoading = true

        // await Order.api().fetchById(this.id)
        const response =  await Order.api().getOrder(this.id)
        if (response.response.status === 200) {
          this.order = response.response.data.data.data
        }
        this.isLoading = false
      }
    }
  }
</script>