<template>
  <transition name="fade">
    <div
        v-show="multipleSelection.length"
        class="multi-selection flex justify-between"
        :class="{'show-arrow':showArrow}">
      <div class="flex">
        <core-text
            bold>
          {{ itemsSelectedText }}
        </core-text>
      </div>

      <div class="flex">
        <template
            v-for="action of bulkActions">  
          <CoreBulkActionConfirm
              v-if="action.onConfirm"
              :key="action.label"
              :bulk-action="action"
              :selected-items="multipleSelection">
            <core-text
                class="bulk-action"
                bold
                clickable>
              {{ action.label }}
            </core-text>
          </CoreBulkActionConfirm>

          <core-button
              v-else
              :key="action.label"
              v-loading="isLoading"
              class="blue-action-button"
              size="mini"
              :type="action.buttonType || 'default'"
              :icon="action.icon || null"
              @click="toggleLoading();action.onClick(multipleSelection)">
            {{ action.label }}
          </core-button>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>
import pluralize from "pluralize"

export default {
  props: {
    multipleSelection: { type: Array, default: () => [] },
    bulkActions: { type: Array, default: () => [] },
    itemLabel: { type: String, default: "item" },
    showArrow: { type: Boolean, default: false }
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    itemsSelectedText() {
      const { itemLabel, multipleSelection } = this
      const itemsSelected = multipleSelection.length

      return `${itemsSelected} ${itemsSelected > 1 ? pluralize(itemLabel) : itemLabel} selected`
    }
  },
  methods: {
    toggleLoading() {
      this.isLoading = true

      setTimeout(() => {
        this.isLoading = false
      }, 3500)
    }
  }
}
</script>

<style lang="scss" scoped>
  .multi-selection {
    background: var(--main-light-blue);
    color: rgba(0,0,0,0.4);
    padding: var(--margin-s) var(--margin-s);
    position: relative;
    z-index: 100;
    width: 100%;

    &.show-arrow::before {
      background: var(--main-light-blue);
      position: absolute;
      z-index: 99;
      left: 20px;
      bottom: -5px;
      width: 15px;
      height: 15px;
      transform: rotate(45deg);
      content: "";
    }

    .blue-action-button {
      border-radius: 0.25em;
      font-size: 0.9em;
      padding: 0.1em 1em;
      transition: var(--main-transition);

      &:hover {
        background: var(--main-apple-blue);
        color: white;
        box-shadow: inset 1px 1px 4px rgba(0,0,0,0.2);
      }
    }
    .bulk-action {
      margin-right: var(--margin-s);
      transition: 0.2s all;

      &:hover {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
</style>