import ProductThumbnailColumn from "./ProductThumbnailColumn"
import ProductQuantityColumn from "./ProductQuantityColumn"
import ProductStatusColumn from "./CaneToadProductStatusColumn"
import CaneToadProductAddToCartColumn
  from "./CaneToadProductAddToCartColumn";
import CaneToadProductCarModelsColumn
  from "./CaneToadProductCarModelsColumn";

const defaultColumns = [
  {
    prop: "SKU",
    label: "SKU"
  },
  {
    prop: 'productThumbnail',
    label: 'Thumbnail',
    Component: ProductThumbnailColumn
  },
  {
    prop: "Name",
    label: "Name",
  },

  {
    prop: "CarModels",
    label: "Car Models",
    Component: CaneToadProductCarModelsColumn
  },
  {
    label: 'Price',
    prop: 'SellPrice',
  },
  {
    label: "Stock",
    Component: ProductQuantityColumn
  },
  {
    label: "Status",
    Component: ProductStatusColumn
  },
  {
    label: "Actions",
    Component: CaneToadProductAddToCartColumn
  }
]

export default defaultColumns