<template>
  <div>
    <core-data-table-v2
        :model="orderManagementModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="orderManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Order } from "@/Modules/Models"
import columns from "../components/OrderManagementTableView/columnsNeto"
import filterConfig from "../components/OrderManagementTableView/filterConfig"
export default {
  name: "OrderManagementTableView",
  data() {
    return {
      filters: filterConfig
    }
  },
  computed: {
    orderManagementModel() {
      return Order
    },
    columns() {
      return columns
    }
  }
}
</script>
