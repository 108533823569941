export default {
  fields: {
    personalDetails: {
      userGroup: {
        type: "select",
        multiple: false,
        filterable: true,
        options: [
          {value: "administrator", label: "Administrator"},
          {value: "editor", label: "Editor"},
          {value: "moderator", label: "Moderator"},
          {value: "customer_service", label: "Customer Service"},
          {value: "guest_access", label: "Guest Access"}
        ],
        value: null,
        label: "User Group",
        props: {
          placeholder: "Please select",
        },
        validation: [{required: true, message: "Please enter a valid User Group", trigger: "blur"}]
      },
      placeholder: {
        type: "placeholder"
      },
      firstName: {
        type: "text",
        value: null,
        label: "First Name",
        props: {
          placeholder: "ie. John",
        },
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      lastName: {
        type: "text",
        value: null,
        label: "Last Name",
        props: {
          placeholder: "ie. Doe",
        },
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      email: {
        type: "email",
        value: null,
        label: "Email Address",
        props: {
          placeholder: "ie. John.doe@example.org",
        },
        validation: [
          {required: true, message: "Please enter email address", trigger: "blur"},
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: ["blur", "change"]
          }
        ]
      },
      color: {
        type: "color-picker",
        value: null,
        label: "Color",
        placeholder: "Pick a color",
        validation: [{required: true, message: "Please select a color", trigger: "blur"}]
      },
    },
    'passwordChange (Optional)': {
      password: {
        width: 24,
        type: "password-input",
        value: null,
        label: "Password",
        props: {
          placeholder: "Enter password",
        },
      }
    }
  }
}
