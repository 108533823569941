const appName = "CTE Admin"

const appConfig = {
  appName: appName,
  base: `${appName}Base`,
  brandName: "Nunki",
  brandColor: {
    h: "14",
    s: "100%",
    l: "55%"
  },
  brandColor2: {
    h: "36",
    s: "106%",
    l: "48%"
  },
  errorReporting: {
    logRocket: null //'7htiof/hal'
  },
  enabledGlobalSearchModels: [],
  scope: {
    get: ({ clientId }) => `/clients/${clientId}`,
    set: (currentPath, clientId) => {
      let newPath = currentPath
      const pathParts = newPath.split("/").slice(1, 4)

      pathParts[1] = clientId
      newPath = `/${pathParts.join("/")}`

      return newPath
    }
  }
}
const secure = true
const url_prefix = secure ? "https://" : "http://"

const apiConfig = {
  lightwerk: {
    api: `${url_prefix}api.canetoadequip.com.au/internal`,
    useToken: false
  },
  google: {
    maps: {
      apiKey: "",
      url: ""
    }
  }
}
const config = {
  appConfig,
  apiConfig,
  main: true,
  path: "",
  enabled: true
}
export default config
