import UserManagement from "@/Modules/UserManagement"
import OrderManagement from "@/Modules/OrderManagement"
import CaneToadProductManagement from "@/Modules/CaneToadProductManagement";
import ShopCategoryManagement from "@/Modules/ShopCategoryManagement";
import BlogManagement from "@/Modules/BlogManagement";
import SystemSettings from "@/Modules/SystemSettings"
import FeedbackManagement from "@/Modules/FeedbackManagement";

export default [
  FeedbackManagement,
  OrderManagement,
  CaneToadProductManagement,
  BlogManagement,
  ShopCategoryManagement,
  UserManagement,
  SystemSettings
]
