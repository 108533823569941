const state = () => {
  return {
    makes: [],
    models: [],
    trims: [],
  }
}

const actions = {
  updateCarModels({ commit }, payload) {
    commit("UPDATE_MAKES", payload.makes)
    commit("UPDATE_MODELS", payload.models)
    commit("UPDATE_TRIMS", payload.trims)
  },
}

const mutations = {
  UPDATE_MAKES(state, payload) {
    state.makes = payload
  },
  UPDATE_MODELS(state, payload) {
    state.models = payload
  },
  UPDATE_TRIMS(state, payload) {
    state.trims = payload
  },
}

const getters = {
  getModelList: (state) => (modelsList) => {

    if (modelsList) {
      return modelsList.map((item) => {
        const newItem = {}
        newItem.make = state.makes.find((make) => make.id === item[0])?.title
        newItem.model = state.models.find((model) => model.id === item[1])?.title
        newItem.trim = state.trims.find((trim) => trim.id === item[2])?.title
        newItem.years = state.trims.find((trim) => trim.id === item[2])?.years
        newItem.title = `${newItem.make} ${newItem.model} ${newItem.trim} ${newItem.years}`
        return newItem
      });
    }
    return null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
