import EntitySelect from "./EditModes/EntitySelect"
import Select from "./EditModes/Select"
import Slider from "./EditModes/Slider"
import Input from "./EditModes/Input"
import Currency from "./EditModes/Currency"
import ColorPicker from "./EditModes/ColorPicker"
import Radio from "./EditModes/Radio"
import TimePicker from "./EditModes/TimePicker"
import DatePicker from "./EditModes/DatePicker"
import TimeSelect from "./EditModes/TimeSelect"
import TimeStepper from "./EditModes/TimeStepper"
import PointSelector from "./EditModes/PointSelector"
import AddressLookup from "./EditModes/AddressLookup"
import NumberInput from "./EditModes/NumberInput"
import Switch from "./EditModes/Switch"
import CheckBox from "./EditModes/CheckBox"
import PasswordInput from "./EditModes/PasswordInput"

import TimeBlockSelector from "./EditModes/TimeBlockSelector";
import MultiImageUpload from "./EditModes/MultiImageUpload";
import CaneToadEquipImageUpload from "./EditModes/CaneToadEquipImageUpload";
import HtmlEditor from "./EditModes/HtmlEditor";
import Suggestion from "./EditModes/Suggestion";
import ProductCard from "./EditModes/ProductCard";

import WeberRinneTestTable from "./EditModes/WeberRinneTestTable";
import MedicareItems from "./EditModes/MedicareItems";

import CarModels from "./EditModes/CarModelSelect";

import Table from "./ViewModes/Table"
import ShoppingCart from "./EditModes/ShoppingCart"

// ViewModes
import DefaultViewMode from "./ViewModes/Default"
import Entity from "./ViewModes/Entity"
import TimeStepperViewMode from "./ViewModes/TimeStepper"
import DateView from "./ViewModes/DateView"

const getFormField = type => {
  switch (type) {
    case "point-selector":
      return PointSelector

    case "time-picker":
    case "time":
      return TimePicker

    case "year":
    case "month":
    case "date":
    case "dates":
    case "datetime":
    case "week":
    case "datetimerange":
    case "daterange":
    case "monthrange":
      return DatePicker

    case "time-select":
      return TimeSelect

    case "time-stepper":
      return TimeStepper
    case "time-stepper-view-mode":
      return TimeStepperViewMode

    case "radio-view-mode":
    case "radio":
      return Radio

    case "select":
      return Select

    case "slider-view-mode":
    case "slider":
      return Slider

    case "entity-select":
      return EntitySelect

    case "entity-select-view-mode":
      return Entity

    case "address-lookup":
      return AddressLookup

    case "number-input":
      return NumberInput

    case "switch":
      return Switch

    case "checkbox":
    case "checkbox-view-mode":
      return CheckBox

    case "currency":
      return Currency

    case "color-picker":
      return ColorPicker

    case "textarea":
    case "text":
    case "email":
    case "tel":
    case "number":
      return Input

    case "password-input":
      return PasswordInput

    case "date-view-mode":
      return DateView

    case "switch-view-mode":
      return Switch

    case "time-block-selector":
      return TimeBlockSelector

    case "multi-image-upload":
      return MultiImageUpload

    case "canetoad-image-upload":
      return CaneToadEquipImageUpload

    case "weber-rinne-test-table":
    case "weber-rinne-test-table-view-mode":
      return WeberRinneTestTable

    case "medicare-items":
    case "medicare-items-view-mode":
      return MedicareItems

    case 'html-editor':
      return HtmlEditor

    case 'suggestion':
      return Suggestion

    case 'product-card':
      return ProductCard

    case 'carmodel-select':
      return CarModels

    case 'table':
      return Table

    case 'shoppingCart':
      return ShoppingCart

    default:
      return DefaultViewMode
  }
}

export default getFormField
