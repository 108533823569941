<template>
  <fragment>
    <CoreScreen
        card
        class="orderManagement-wrapper"
        header="OrderManagement"
        icon="OrderManagementIcon"
        :create-button="[{ label: 'Create Order', onCreate: onCreateOrder }]">
      >
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Order } from "@/Modules/Models"
export default {
  name: "OrderManagement",
  methods: {
    onCreateOrder() {
      new Order().Actions.createOrderDialog()
    }
  }
}
</script>
