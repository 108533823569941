import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import moment from "moment"

class Order extends ExtendedModel {
  static entity = "Order"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get customerFullName() {
    if (this.customerFirstName && this.customerLastName) {
      return `${this.customerFirstName} ${this.customerLastName}`
    }
    return "anonymous"
  }

  get createdDateTime() {
    return moment(this.createdAt).format("DD/MM/YYYY, h:mma")
  }

  get updatedDateTime() {
    return moment(this.createdAt).format("DD/MM/YYYY, h:mma")
  }

  get priceCostAud() {
    return `${parseFloat(this.priceCost).toFixed(2)} AUD`
  }

  get pricePaidAud() {
    return `${parseFloat(this.pricePaid).toFixed(2)} AUD`
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default Order
