import { render, staticRenderFns } from "./OrderManagementForm.vue?vue&type=template&id=68dd2385&scoped=true"
import script from "./OrderManagementForm.vue?vue&type=script&lang=js"
export * from "./OrderManagementForm.vue?vue&type=script&lang=js"
import style0 from "./OrderManagementForm.vue?vue&type=style&index=0&id=68dd2385&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dd2385",
  null
  
)

export default component.exports