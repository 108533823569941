<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      version="1.0"
      style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;"
      viewBox="0 0 10000 12500"
      x="0px"
      y="0px"
      fill-rule="evenodd"
      clip-rule="evenodd"
      :fill="color"
      class="svg-icon"><defs />
    <g><path
           class="fil0"
           d="M2291 1637l5418 0c456,0 829,374 829,830 0,1193 0,2386 0,3579 0,456 -374,830 -829,830l-1662 0 0 1279 682 0c137,0 137,208 0,208 -1153,0 -2306,0 -3458,0 -137,0 -137,-208 0,-208l682 0 0 -1279 -1662 0c-456,0 -829,-374 -829,-830 0,-1193 0,-2386 0,-3579 0,-456 373,-830 829,-830zm2813 5239l0 639c0,137 -208,137 -208,0l0 -639 -735 0 0 1279 1678 0 0 -1279 -735 0zm-3434 -1551l6660 0 0 -2858c0,-342 -279,-622 -621,-622l-5418 0c-342,0 -621,280 -621,622l0 2858zm6660 209l-6660 0 0 512c0,342 279,621 621,621 1806,0 3612,0 5418,0 341,0 621,-279 621,-621l0 -512z" />
      <path
          class="fil0"
          d="M2510 2885c-137,0 -137,-208 0,-208l238 0c50,0 92,35 102,82l100 329 1664 0c72,1 123,72 97,141l-271 717c-13,44 -53,76 -100,76l-1244 0c-45,-1 -86,-29 -100,-74l-325 -1063 -161 0zm504 411l158 517 1096 0 196 -517 -1450 0z" />
      <path
          class="fil0"
          d="M5883 3042c-137,0 -137,-208 0,-208l1620 0c137,0 137,208 0,208l-1620 0z" />
      <path
          class="fil0"
          d="M5883 3768c-137,0 -137,-208 0,-208l1620 0c137,0 137,208 0,208l-1620 0z" />
      <path
          class="fil0"
          d="M3262 4105c127,0 230,103 230,230 0,127 -103,230 -230,230 -126,0 -230,-104 -230,-230 0,-127 103,-230 230,-230zm0 208c-12,0 -22,10 -22,22 0,13 9,22 22,22 12,0 22,-10 22,-22 0,-12 -10,-22 -22,-22z" />
      <path
          class="fil0"
          d="M4083 4105c127,0 230,103 230,230 0,127 -104,230 -230,230 -127,0 -231,-104 -231,-230 0,-127 104,-230 231,-230zm0 208c-13,0 -22,10 -22,22 0,13 9,22 22,22 12,0 21,-10 21,-22 0,-12 -10,-22 -21,-22z" /></g></svg>
</template>
<script>
  export default {
    name: "CaneToadProductManagementIcon",
    props: {
      color: {
        type: String,
        default: "#000000"
      }
    }
  }
</script>
<style scoped>
  .svg-icon {
    width: 100%;
  }
</style>
