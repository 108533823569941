<template>
  <div>
    <core-data-table-v2
        :model="shopCategoryManagementModel"
        :columns="columns"
        table-name="shopCategoryManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { ShopCategory } from "@/Modules/Models"
import columns from "../components/ShopCategoryManagementTableView/columns"
export default {
  name: "ShopCategoryManagementTableView",
  computed: {
    shopCategoryManagementModel() {
      return ShopCategory
    },
    columns() {
      return columns
    }
  }
}
</script>
