<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation">
    <el-input
        v-bind="props"
        v-on="$listeners">
      <template
          v-if="props.prepend"
          slot="prepend">
        {{ props.prepend }}
      </template>
      <template
          v-if="props.append"
          slot="append">
        {{ props.append }}
      </template>
    </el-input>
  </el-form-item>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    props() {
      return { type: "text", ...elementUIDefaultProps, ...this.$attrs }
    }
  }
}
</script>
