<template>
  <CoreDialog
      :title="title"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <CaneToadProductImportForm
          :dialog-props="dialogProps" />
    </template>
  </CoreDialog>
</template>

<script>
  import CaneToadProductImportForm from "../../forms/CaneToadProductImportForm/CaneToadProductImportForm"

  export default {
    name: "CaneToadProductImportDialog",
    components: {
      CaneToadProductImportForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      },
      title() {
       return "Import Neto Products"
      }
    }
  }
</script>