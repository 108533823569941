<template>
  <div class="html-editor-container">
    <VueEditor v-model="editorContent" />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "HtmlEditor",
  components: { VueEditor },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    editorContent: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>