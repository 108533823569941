import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/openAi"

class openAi extends ExtendedModel {
  static entity = "openAi"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async create(data) {
        const url = `${baseURL}${endpoint}/create`
        return this.post(url, { data: data })
      },
    }
  }
}
export default openAi
