export default {
  tabs: [
    { label: "Billing Details" },
    { label: "Shipping Details" },
    { label: "Shopping List" },
    { label: "Order Details" },
  ],
  fields: {
    billingDetails: {
      _options: { tab: 0 },
      Email: {
        width: 12,
        type: "text",
        label: "Email",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      BillPhone: {
        width: 12,
        type: "text",
        value: null,
        label: "Phone",
        placeholder: "ie. 0444444444",
        validation: [{required: true, message: "Please enter mobile phone number", trigger: "blur"}]
      },
      BillFirstName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      BillLastName: {
        width: 6,
        type: "text",
        label: "Last Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      BillCompany: {
        width: 12,
        type: "text",
        label: "Company Name",
        placeholder: "ACME Inc.",
      },
      BillStreetLine1: {
        width: 12,
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Fake Street",
        validation: [{required: true, message: "Please enter street name", trigger: "blur"}]
      },
      BillCity: {
        width: 12,
        type: "text",
        value: null,
        label: "City/Suburb",
        placeholder: "ie. Brisbane",
        validation: [{required: true, message: "Please enter city/suburb", trigger: "blur"}]
      },
      BillPostCode: {
        width: 4,
        type: "text",
        value: null,
        label: "Post Code",
        placeholder: "ie. 4000",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      BillState: {
        width: 8,
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. QLD",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      BillCountry: {
        width: 8,
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. AU",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
    },
    shippingDetails: {
      _options: { tab: 1 },
      ShipFirstName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      ShipLastName: {
        width: 6,
        type: "text",
        label: "Last Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      ShipPhone: {
        width: 12,
        type: "text",
        value: null,
        label: "Phone",
        placeholder: "ie. 0444444444",
        validation: [{required: true, message: "Please enter mobile phone number", trigger: "blur"}]
      },
      ShipCompany: {
        width: 12,
        type: "text",
        label: "Company Name",
        placeholder: "ACME Inc.",
      },
      placeholder0: {
        type: "placeholder",
        width: 12,
      },
      ShipStreetLine1: {
        width: 12,
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Fake Street",
        validation: [{required: true, message: "Please enter street name", trigger: "blur"}]
      },
      ShipCity: {
        width: 12,
        type: "text",
        value: null,
        label: "City/Suburb",
        placeholder: "ie. Brisbane",
        validation: [{required: true, message: "Please enter city/suburb", trigger: "blur"}]
      },
      ShipPostCode: {
        width: 4,
        type: "text",
        value: null,
        label: "Post Code",
        placeholder: "ie. 4000",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      ShipState: {
        width: 8,
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. QLD",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      ShipCountry: {
        width: 8,
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. AU",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
    },
    shoppingCart: {
      _options: {tab: 2},
      OrderLine: {
        width: 24,
        type: "shoppingCart",
      }
    },
    orderSummary: {
      _options: { tab: 3 },
      ShippingOption: {
        width: 8,
        type: "select",
        label: "Shipping Option",
        initialValue: "pickup",
        options: [
          {label: "Pickup", value: "pickup"},
          {label: "Ship to Billing Address", value: "ship_to_billing_address"},
          {label: "Ship to Shipping Address", value: "ship_to_shipping_address"},
        ]
      },
      ShippingTotal: {
        width: 8,
        type: "number",
        label: "Shipping Fee",
        props: {
          append: "AUD",
        }
      },
      GrandTotal: {
        width: 8,
        type: "text",
        label: "Invoice Total (incl. Shipping Fee)",
        props(Form) {
          if (Form.formData.shoppingCart.OrderLine?.value?.total) {
            let total = parseFloat(Form.formData.shoppingCart.OrderLine?.value.total).toFixed(2)

            if (Form.formData.orderSummary.ShippingTotal.value) {
              // add total by summing up the shipping fee
              total = (parseFloat(total) + parseFloat(Form.formData.orderSummary.ShippingTotal.value)).toFixed(2)
            }
            Form.formData.orderSummary.GrandTotal.value = total
          }
          return {
            append: "AUD",
          }
        },
      },
      placeholder2: {
        width: 12,
        type: "placeholder",
      },
      DeliveryInstruction: {
        width: 24,
        type: "textarea",
        label: "Delivery Instructions",
      }
    },
  }
}
