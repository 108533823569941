<template>
  <div class="form-table-container">
    <table class="item-table">
      <thead>
        <tr>
          <th
              v-for="(column, index) in columns"
              :key="`column-${index}`"
              :width="`${column.width}%`">
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
            v-for="(item, itemIndex) in value"
            :key="`item-${itemIndex}`">
          <td
              v-for="(column, index) in columns"
              :key="`column-${index}`">
            <template v-if="column.format === 'money'">
              {{ item[column.field] | money }}
            </template>
            <template v-else>
              {{ item[column.field] }}
            </template>
            {{ column.append }}
            <slot :name="column.name" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "FormTable",
  filters: {
    money(value) {
      if (!value) return "";
      return parseFloat(value).toFixed(2)
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .item-table {
    width: 100%;
    border: 1px solid var(--main-border-color);

    thead {
      background: var(--main-light-grey);
    }
    tbody {
      tr {
        &:nth-child(even) {
          td {
            background: var(--main-light-grey)
          }
        }
      }
      td {
        padding: 0.25em 0.5em;
        line-height: 1.5;
      }
    }
  }
</style>