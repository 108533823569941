export default {
  tabs: [
    { label: "Order Details" },
    { label: "Billing Details" },
    { label: "Shipping Details" },
    { label: "Item List" },
  ],
  fields: {
    orderDetails: {
      _options: { tab: 0 },
      OrderID: {
        width: 8,
        type: "text",
        label: "Order ID",
        props: {
          readonly: true,
        }
      },
      DatePlaced: {
        type: "date",
        width: 8,
        label: "Order Date",
        props: {
          readonly: true,
          format: "dd/MM/yyyy",
        }
      },
      GrandTotal: {
        width: 8,
        type: "text",
        label: "Invoice Total",
        props: {
          readonly: true,
          append: "AUD",
        }
      },
      ShippingOption: {
        width: 8,
        type: "select",
        label: "Shipping Option",
        props: {
          readonly: true,
        }
      },
      placeholder2: {
        width: 12,
        type: "placeholder",
      },
      DeliveryInstruction: {
        width: 24,
        type: "textarea",
        label: "Delivery Instructions",
      }
    },
    billingDetails: {
      _options: { tab: 1 },
      Username: {
        width: 6,
        type: "text",
        props: {
          readonly: true,
        },
        label: "Username",
        validation: [{required: true, message: "Please enter a username", trigger: "blur"}]
      },
      BillFirstName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      BillLastName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      BillPhone: {
        width: 6,
        type: "text",
        value: null,
        label: "Phone",
        placeholder: "ie. 0444444444",
        validation: [{required: true, message: "Please enter mobile phone number", trigger: "blur"}]
      },
      BillStreetLine1: {
        width: 8,
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Fake Street",
        validation: [{required: true, message: "Please enter street name", trigger: "blur"}]
      },
      BillCity: {
        width: 4,
        type: "text",
        value: null,
        label: "City/Suburb",
        placeholder: "ie. Brisbane",
        validation: [{required: true, message: "Please enter city/suburb", trigger: "blur"}]
      },
      BillPostCode: {
        width: 4,
        type: "text",
        value: null,
        label: "Post Code",
        placeholder: "ie. 4000",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      BillState: {
        width: 4,
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. QLD",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      BillCountry: {
        width: 4,
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. AU",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
    },
    shippingDetails: {
      _options: { tab: 2 },
      ShipFirstName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter first name", trigger: "blur"}]
      },
      ShipLastName: {
        width: 6,
        type: "text",
        label: "First Name",
        placeholder: "Doe",
        validation: [{required: true, message: "Please enter last name", trigger: "blur"}]
      },
      ShipPhone: {
        width: 6,
        type: "text",
        value: null,
        label: "Phone",
        placeholder: "ie. 0444444444",
        validation: [{required: true, message: "Please enter mobile phone number", trigger: "blur"}]
      },
      ShipStreetLine1: {
        width: 8,
        type: "text",
        value: null,
        label: "Street",
        placeholder: "ie. 123 Fake Street",
        validation: [{required: true, message: "Please enter street name", trigger: "blur"}]
      },
      ShipCity: {
        width: 4,
        type: "text",
        value: null,
        label: "City/Suburb",
        placeholder: "ie. Brisbane",
        validation: [{required: true, message: "Please enter city/suburb", trigger: "blur"}]
      },
      ShipPostCode: {
        width: 4,
        type: "text",
        value: null,
        label: "Post Code",
        placeholder: "ie. 4000",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      ShipState: {
        width: 4,
        type: "text",
        value: null,
        label: "State",
        placeholder: "ie. QLD",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
      ShipCountry: {
        width: 4,
        type: "text",
        value: null,
        label: "Country",
        placeholder: "ie. AU",
        validation: [{required: true, message: "Please enter post code", trigger: "blur"}]
      },
    },
    itemList: {
      _options: {tab: 3},
      OrderLine: {
        width: 24,
        type: "table",
        props: {
          columns: [
            { field: 'Quantity', label: 'Quant.', width: 5, type: 'text', append: 'x', props: { readonly: true } },
            { field: 'ProductName', width: 30, label: 'Product', type: 'text', props: { readonly: true  } },
            { field: 'SKU', label: 'SKU', width: 15, type: 'text', props: { readonly: true, append: '%' } },
            { field: 'UnitPrice', label: 'Unit Price', width: 10, type: 'text', format: 'money', append: 'AUD', props: { readonly: true,  } },
          ]
        }
      }
    }
  }
}
