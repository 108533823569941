<template>
  <fragment>
    <CoreScreen
        card
        class="canetoad-productManagement-wrapper"
        header="Product Management"
        icon="CaneToadProductManagementIcon"
        :create-button="[{ label: 'Create Product', onCreate: onCreateProduct }, { label: 'Import Products', onCreate: onImportProducts }]">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { CaneToadProduct } from "@/Modules/Models"
export default {
  name: "CaneToadProductManagement",
  methods: {
    onCreateProduct() {
      new CaneToadProduct().Actions.openProductDialog()
    },
    onImportProducts() {
      new CaneToadProduct().Actions.openProductImportDialog()
    }
  }
}
</script>
