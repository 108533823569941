<template>
  <div
      ref="uploadContainer"
      class="multi-image-upload-container">
    <div class="file-upload-button-container">
      <input
          ref="fileInput"
          class="el-button"
          type="file"
          accept="image/*"
          :multiple="options.multiple || false"
          @change="handleFileChange">
    </div>
    <ul class="file-list">
      <li
          v-for="(file, index) in files"
          :key="`file-${index}`">
        <div>
          <i class="el-icon-picture-outline" />
          <span class="file-name">{{ file.name }}</span>
        </div>
        <el-button
            role="button"
            size="mini"
            type="danger"
            @click.prevent="handleRemoveFile(index)">
          <i class="el-icon-delete" />
        </el-button>
      </li> 
    </ul>
    <div
        v-if="imageSrcList.length > 0"
        class="image-list-container">
      <draggable
          v-model="imageSrcList"
          class="image-list-container">
        <div
            v-for="(image, index) in imageSrcList"
            :key="index"
            class="image-list-item">
          <span
              class="delete-image"
              @click="handleRemoveImage(index)">
            <i class="el-icon-delete" />
          </span>
          <div class="image-list-item__inner">
            <el-image
                :src="`https://api.canetoadequip.com.au/pic/120x120/media/productImages/${image}`"
                alt="Product Image"
                :preview-src-list="imagePreviewList(index)"
                lazy />
          </div>
        </div>
      </draggable>
      <div class="image-list-container">
        <div
            v-for="(image, index) in removedImagesList"
            :key="index"
            class="image-list-item recycle">
          <span
              class="recycle-image"
              @click="handleRecycleImage(index)">
            <i class="el-icon-refresh" />
          </span>
          <div
              class="image-list-item__inner"
              style="opacity:0.5">
            <el-image
                :src="`https://api.canetoadequip.com.au/pic/120x120/media/productImages/${image}`"
                alt="Product Image"
                lazy />
          </div>
        </div>
      </div>
    </div>
    <div
        v-if="files.length > 0"
        class="upload-options-container">
      <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="uploadFiles">
        Upload Images
      </el-button>
      <el-button
          style="margin-left: 10px;"
          size="small"
          type="danger"
          @click.prevent="handleClearAllUploads">
        Clear All
      </el-button>
    </div>
  </div>
</template>

<script>
import { Upload } from "@/Modules/Models"
import draggable from 'vuedraggable'

export default {
  name: "MultiImageUpload",
  components: {
    draggable
  },
  props: {
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      imageBlog: null,
      imageUrl: '',
      enabledOptions: [],
      imageSrcList: [],
      files: [],
      uploadEnabledOptionValue: null,
      isUploading: [],
      uploadedImagesList: [],
      removedImagesList: [],
    };
  },
  watch: {
    imageSrcList: {
      handler: function () {
        this.$emit('input', this.imageSrcList)
      },
      deep: true
    }
  },
  mounted() {
    if (this.value) {
      this.imageSrcList = this.value;
    }
  },
  methods: {
    imagePreviewList(index) {

      const imageList = this.imageSrcList.map((image) => {
        return `https://api.canetoadequip.com.au/pic/600x0/media/productImages/${image}`;
      });

      // sort imageList to make the first item match the index param
      imageList.sort((a) => {
        return a === imageList[index] ? -1 : 1;
      });
      return imageList
    },
    handleSorting(event) {
      //nextTick is required to prevent the DOM from updating before the array is updated
      this.$nextTick(() => {
        this.imageSrcList.splice(event.newIndex, 0, this.imageSrcList.splice(event.oldIndex, 1)[0])
      });

    },
    handleClearAllUploads() {
      this.files = [];
    },
    handleFileChange(event) {
      this.files = Array.from(event.target.files);
    },
    handleRemoveFile(index) {
      this.files.splice(index, 1);
    },
    handleRemoveImage(index) {
      this.removedImagesList.push(this.imageSrcList[index]);
      this.imageSrcList.splice(index, 1);
    },
    handleRecycleImage(index) {
      this.imageSrcList.push(this.removedImagesList[index]);
      this.removedImagesList.splice(index, 1);
    },
    async uploadFiles() {

      // make the following function async

      this.files.forEach(async (file, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const result = reader.result;
          if (result) {
            const fileUploadData = {
              name: file.name,
              type: file.type,
              size: file.size,
              imageBase64: result,
              targetFolder: "productImages"
            };
            const uploadResult = await Upload.api().uploadImage(fileUploadData)
            // Handle the upload result here
            if (uploadResult.response.data.success) {
              // remove file from files array
              this.handleRemoveFile(index)

              this.imageSrcList.push(uploadResult.response.data.data.data.fileName)
            }
          }
        };
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.multi-image-upload-container {
  background: var(--color-basic-black-opacity-2);
  padding: var(--padding-l) var(--padding-l) var(--padding-xl) var(--padding-l);
  border-radius: var(--border-radius-xs);

  .file-upload-button-container {
    text-align: center;
  }

  .upload-options-container {
    margin: var(--margin-m) 0;
  }

  .option-container {
    padding: var(--margin-xs) var(--padding-l);
    border-radius: var(--border-radius-s);
    background: var(--color-bridj-light-background);

  }
  .option-container--active {
    background: #d9edff;
  }

  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #FFF;
    width: 178px;
    height: 178px;
    margin-bottom: var(--margin-m);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--padding-m);
    word-break: keep-all;

    .hint {
      text-align: center;
    }

    button {
      position: absolute;
      left: 50%;
      margin-left: -30%;
      bottom: 20px;
    }
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
}
.file-list {
  max-height: 250px;
  overflow-y: auto;
  margin-top: var(--margin-m);

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin: var(--margin-xs) 0;
    padding: var(--padding-xs) var(--padding-m);
    border-radius: var(--border-radius-s);

    .file-name {
      display: inline-block;
      margin-left: var(--margin-s);
      line-height: 1;
    }
  }
}

.image-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-top: 10px;
  width: 100%;
  gap: 15px 10px;

  .image-list-item {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.12);
    border-radius: var(--border-radius-xs);
    position: relative;
    margin: 0 var(--margin-s);
    min-width: 120px;
    max-width: 120px;
    flex: 1;
    justify-content: center;
    align-items: center;

    &:first-child(:not(.recycle)) {
      border: 2px solid var(--main-success-green);
        &:after {
          content: "Main Image";
          border: 1px solid var(--main-success-green);
          border-radius: var(--border-radius-xs);
          position: absolute;
          width: 100%;
          left: 0;
          color: var(--main-success-green);
          text-align: center;
          line-height: 1.5;
          margin-top: 5px;
          background: var(--main-light-green);
      }

    }

    &__inner {
      width: 100%;

      overflow: hidden;
      border-radius: var(--border-radius-xs);

    }

    .delete-image {
      position: absolute;
      background: var(--main-red);
      border-radius: var(--border-radius-m);
      color: #fff;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      top: -5px;
      right: -5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--main-dark);
      }
    }

    .recycle-image {
      position: absolute;
      background: var(--main-success-green);
      border-radius: var(--border-radius-m);
      color: #fff;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      top: -5px;
      right: -5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background: var(--main-dark);
      }
    }


  }
}
::v-deep .el-upload,
::v-deep .el-upload-dragger {
  width: 100%;
}
</style>