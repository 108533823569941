import config from "./config"
import routes from "./routes"
import Base from "@/Modules/Core/Base"
import Models from "@/Modules/Models"

const AppSettings = {
  ...config,
  component: Base,
  children: routes,
  models: Object.values(Models)
}

export default AppSettings
