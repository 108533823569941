import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class Blog extends ExtendedModel {
  static entity = "Blog"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

  get articleThumbnail() {
    if (this.image) {
      return `https://api.canetoadequip.com.au/pic/304x304/media/productImages/${this.image}`
    }
    return null
  }
}
export default Blog
