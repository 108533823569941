<template>
  <fragment>
    <CoreScreen
        card
        class="blogManagement-wrapper"
        header="BlogManagement"
        icon="BlogManagementIcon"
        :create-button="{ label: 'Create Blog', onCreate: createBlogManagement }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Blog } from "@/Modules/Models"
export default {
  name: "BlogManagement",
  methods: {
    createBlogManagement() {
      new Blog().Actions.openBlogManagementDialog()
    }
  }
}
</script>
