<template>
  <form
      class="login-form"
      @submit.prevent="submit()">
    <div
        class="form-group">
      <div class="input-group">
        <div class="input">
          <label for="email">
            Email/Username
          </label>
          <input
              id="email"
              ref="email"
              v-model="formData.email"
              type="text"
              data-test-id="authEmail"
              class="form-control neumorphic"
              :class="{ disabled: loading }"
              placeholder="john.doe@example.org">
        </div>
      </div>

      <div class="input-group">
        <div class="input">
          <label for="password">
            Password
          </label>
          <input
              id="password"
              v-model="formData.password"
              type="password"
              data-test-id="authPassword"
              class="form-control neumorphic"
              :class="{ disabled: loading }"
              placeholder="Password">
        </div>
      </div>

      <transition name="fade">
        <template v-if="canSubmit">
          <button
              type="submit"
              class="button btn-confirm"
              :class="{ disabled: loading }"
              :disabled="loading">
            Login
          </button>
        </template>
      </transition>

      <core-button
          v-if="$isPermitted('auth:auth0')"
          class="auth0-button button flex justify-center"
          @click="auth0Login">
        Login with 
        <Auth0Logo class="auth0-logo" />
      </core-button>

      <transition name="fade">
        <template v-if="loginMessage">
          <div
              class="system-message-container"
              :class="loginStatus">
            {{ loginMessage }}
          </div>
        </template>
      </transition>
    </div>
  </form>
</template>

<script>
import Auth0Logo from "../assets/SVG/Auth0Logo"
import { User } from "@/Modules/Models"

export default {
  name: "LoginForm",
  components: { Auth0Logo },
  props: {
    value: { type: Boolean, required: true },
    setAnimationState: { type: Function, required: true }
  },
  data() {
    return {
      formData: { email: null, password: null },
      validation: null,
      loading: false,
      loginMessage: null,
      loginStatus: ""
    }
  },
  computed: {
    canSubmit() {
      return (this.formData.email && this.formData.password) || false
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.email.focus())
  },
  methods: {
    auth0Login() {
      this.$auth.loginWithRedirect()
    },
    sendToast(toast) {
      this.$store.dispatch("core/notifications/addToNotifications", toast)
    },
    async submit() {
      this.loading = true
      this.setAnimationState(true)

      try {
        await User.api().login(this.formData)
        this.sendToast({
          component: "Toast",
          type: "success",
          title: "Successful Login",
          message: "We have successfully logged you in.",
          visible: true,
          decay: 3500
        })
        this.loginStatus = "success"
      } catch (error) {
        this.loginMessage = error
        this.loading = false
        this.loginStatus = "error"
        this.setAnimationState(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
}

.auth0-button {
  margin-top: var(--margin-xl) !important;
}

.login-form {
  padding: 0;
  h2 {
    color: var(--main-primary);
  }
}
.system-message-container {
  width: 100%;
  text-align: center;
  font-weight: 500;
  margin: 1em;
}
</style>
