export default {
  fields: {
    articleDetails: {
      title: {
        type: "text",
        value: null,
        label: "Blog Title",
        placeholder: "ie. Blog Title",
        validation: [{required: true, message: "Please enter a blog title", trigger: "blur"}]
      },
      subtitle: {
        type: "text",
        value: null,
        label: "Subtitle",
        validation: [{required: true, message: "Please enter a subtitle", trigger: "blur"}]
      },
      description: {
        width: 24,
        type: "html-editor",
        label: "Product Description",
      },
      image: {
        label: "Article Image",
        width: 24,
        type: "canetoad-image-upload",
        options: {
          multiple: false,
        },
        value: true,
      },
      authorName: {
        type: "text",
        value: null,
        label: "Author Name",
        placeholder: "ie. John Smith",
        validation: [{required: true, message: "Please enter an author name", trigger: "blur"}]
      }
    },
    seoDetails: {
      tags: {
        type: 'select',
        label: 'Tags',
        props: {
          multiple: true,
          filterable: true,
          allowCreate: true,
        }
      },
      productIds: {
        type: "entity-select",
        props: {
          entity: "CaneToadProduct",
          filterable: true,
          clearable: true,
          multiple: true,
        },
        label: "Related Products",
        options: [],
      }
    }
  }
}
