import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    position: null,
    title: null,
    description: null,
    tags: null,
    backgroundImage: null,
    parentId: null,
    active: null,
    visibility: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition