<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <ShopCategoryManagementForm
          :dialog-props="dialogProps"
          :shop-category-management-data="shopCategoryManagementData" />
    </template>
  </CoreDialog>
</template>

<script>
  import ShopCategoryManagementForm from "../../forms/ShopCategoryManagementForm/ShopCategoryManagementForm"
  import { ShopCategory } from "@/Modules/Models"
  import startCase from "lodash/startCase"

  export default {
    name: "ShopCategoryManagementDialog",
    components: {
      ShopCategoryManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      shopCategoryManagementData() {
        return ShopCategory.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.shopCategoryManagementData) {
          return `${startCase(this.mode)}ing Category ${this.shopCategoryManagementData.title} (ID: ${this.id})`
        }

        return "Create New Category"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchShopCategoryManagement()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchShopCategoryManagement() {
        this.isLoading = true

        await ShopCategory.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>