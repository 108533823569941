import routes, { apiActions } from "./routes"

/*
id Primary	int(11)
userId	smallint(3)
companyId	smallint(3)
title	varchar(255)
subtitle	varchar(255)
description	text
image	varchar(255)
categoryId	tinyint(3)
tags	text
productIds	text
views	int(12)
slug (unique)	varchar(255)
comments	smallint(4)
createdAt	datetime
updatedAt	datetime
authorName	varchar(255)
active	tinyint(1)
visibility	tinyint(1)
*/

const attributes = {
    id: 1,
    active: 1,
    title: null,
    subtitle: null,
    description: null,
    image: null,
    categoryId: null,
    tags: null,
    productIds: null,
    views: null,
    slug: null,
    createdAt: null,
    updatedAt: null,
    authorName: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition