import BlogManagement from "./BlogManagement"
import BlogManagementIcon from "./components/BlogManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const BlogManagementModule = {
  moduleGrouping: "Content Management",
  name: "BlogManagement",
  menuName: "Blog Management",
  component: BlogManagement,
  path: "BlogManagement",
  icon: BlogManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "Manage your blog articles here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("BlogManagement", BlogManagement)
    Vue.component("BlogManagementIcon", BlogManagementIcon)
  }
}
export default BlogManagementModule
