import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openProductDialog(options)
  }

  openProductDialog(options = {}) {
    const type = "CaneToadProductDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openProductImportDialog(options = {}) {
    const type = "CaneToadProductImportDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View Product",
        icon: "el-icon-view",
        onClick: () => this.openProductDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Product",
        icon: "el-icon-edit",
        onClick: () => this.openProductDialog({ mode: "edit" })
      },
      {
        visible: true,
        label: "Duplicate Product",
        icon: "el-icon-document-copy",
        onClick: () => this.openProductDialog({ mode: "duplicate" })
      },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this Product?",
          confirmButtonText: "Delete Product"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
