import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/uploads"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    uploadImage: `${endpoint}/uploadImage/`
  }
})

const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  uploadImage(options = {}) {
    const url = endpoints.uploadImage({ ...options })

    return this.post(url, options)
  }
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
