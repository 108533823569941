<template>
  <div class="CarModelSelect-container">
    <el-cascader
        v-model="value"
        style="width: 100%"
        :options="options"
        :props="{ multiple: true, checkStrictly: true }"
        clearable
        @change="handleChange" />
  </div>
</template>

<script>
import { CarModels } from "@/Modules/Models"

export default {
  name: "CarModelSelect",
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      makes: [],
      models: [],
      trims: [],
      isLoading: false
    }
  },
  computed: {
    options() {
      return this.makes.map(make => {
        return {
          value: make.id,
          label: make.title,
          children: this.models
            .filter(model => model.make_id === make.id)
            .map(model => {
              return {
                value: model.id,
                label: model.title,
                children: this.trims
                  .filter(trim => trim.model_id === model.id)
                  .map(trim => {
                    return {
                      value: trim.id,
                      label: `${trim.title}  ${trim.years}`
                    }
                  })
              }
            })
        }
      })
    }
  },
  mounted() {
    this.getCarModels()
  },
  methods: {
    handleChange(value) {
      this.$emit("input", value)
    },
    async getCarModels() {
      this.isLoading = true
      const response = await CarModels.api().fetchAll()
      this.makes = response.response.data.data.makes
      this.models = response.response.data.data.models
      this.trims = response.response.data.data.trims
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>