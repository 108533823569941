<template>
  <div class="form">
    <CoreFormBuilderV2
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { CaneToadProduct } from "@/Modules/Models"
  import formConfig from "./formConfig"

  export default {
    name: "CaneToadProductManagementForm",
    props: {
      dialogProps: { type: Object, required: true },

    },
    data() {
      return {
        isLoading: false,
        formConfig
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()
          const response = await CaneToadProduct.api().importNetoProducts(flatFormData)
          let error = false

          let notificationsPayload = {}

          if (!response.response.data.success) {
            notificationsPayload = {
              component: "Toast",
              type: "error",
              title: "Error",
              message: response.response.data.error,
              visible: true,
              decay: 3500
            }
            error = true;
            Form.setSubmitting(false)
          } else {
            notificationsPayload = {
              component: "Toast",
              type: "success",
              title: "Thank You",
              message: "Your Product details have been successfully imported.",
              visible: true,
              decay: 3500
            }
            error = false
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          if (!error) {
            await CaneToadProduct.api().fetchAll()
            this.dialogProps.closeDialog()
          }

        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>