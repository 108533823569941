import ShopCategoryManagementStatusColumn from "./ShopCategoryManagementStatusColumn";
import ShopCategoryManagementDescriptionColumn from "./ShopCategoryManagementDescriptionColumn";
const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "title",
      label: "Title"
    },
  {
    label: "Description",
    Component: ShopCategoryManagementDescriptionColumn
  },
    {
      prop: "ParentCategory",
      label: "Parent Category",
    },
    {
      label: "Status",
      Component: ShopCategoryManagementStatusColumn
    }
  ]

export default defaultColumns