import routes, { apiActions } from "./routes"

const attributes = {
    id: null,
    prompt: null,
    result: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition