<template v-else-if="formItem.type === 'select'">
  <el-select
      v-model="formItem.value"
      :loading="loading"
      :filterable="formItem.filterable || false"
      :multiple="formItem.multiple || false"
      class="dpl-block"
      :placeholder="formItem.placeholder"
      :data-testid="`form-${formName}-input-${index2}`"
      :clearable="formItem.clearable">
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      <span
          class="truncate"
          style="float: left; margin-right: 1rem;">
        {{ item.label }}
      </span>

      <span style="float: right; color: #8492a6; font-size: 0.8em">{{
        item.value
      }}</span>
    </el-option>
  </el-select>
</template>

<script>
import Models from "@/Modules/Models"

export default {
  props: {
    formItem: { type: Object, default: () => ({}) },
    formName: { type: [String, Number], required: true },
    index2: { type: [String, Number], required: true }
  },
  data() {
    return {
      entity: Models[this.formItem.entity],
      loading: false
    }
  },
  computed: {
    options() {
      return this.entity.all().map(record => record.valueList)
    }
  },
  beforeMount() {
    this.loading = true

    this.entity.api().fetchAll()

    this.loading = false
  }
}
</script>
