<template>
  <div>
    <core-data-table-v2
        :model="blogModel"
        :columns="columns"
        table-name="blogManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Blog } from "@/Modules/Models"
import columns from "../components/BlogManagementTableView/columns"
export default {
  name: "BlogManagementTableView",
  computed: {
    blogModel() {
      return Blog
    },
    columns() {
      return columns
    }
  }
}
</script>
