export default {
  fields: {
    reviewDetails: {
      nickname: {
        type: "text",
        value: null,
        label: "Reviewer Nickname",
        placeholder: "ie. Doe",
        validation: [{required: true, message: "Please enter nickname", trigger: "blur"}]
      },
      productId: {
        type: "entity-select",
        props: {
          entity: "CaneToadProduct",
          filterable: true,
          clearable: true,
        },
        label: "Product",
        options: [],
        validation: [{required: true, message: "Please select a product", trigger: "blur"}]
      },
    },
    ratingDetails: {
      quality: {
        width: 8,
        type: "number",
        initialValue: 5,
        props: {
          min: 1,
          max: 5,
        },
        label: "Quality",
        validation: [{required: false, message: "Please enter a rating", trigger: "blur"}]
      },
      installation: {
        width: 8,
        type: "number",
        initialValue: 5,
        props: {
          min: 1,
          max: 5,
        },
        label: "Installation",
        validation: [{required: false, message: "Please enter a rating", trigger: "blur"}]
      },
      satisfaction: {
        width: 8,
        type: "number",
        initialValue: 5,
        props: {
          min: 1,
          max: 5,
        },
        label: "Satisfaction",
        validation: [{required: false, message: "Please enter a rating", trigger: "blur"}]
      },
      phone: {
        type: "phone",
        value: null,
        label: "Phone Number",
        placeholder: "ie. +61 411 111 111",
        validation: [{required: false, message: "Please enter a valid Phone Number", trigger: "blur"}]
      }
    },
    feedback: {
      description: {
        width: 24,
        type: "html-editor",
        label: "Review Text (Description)",
        placeholder: "",
        props: {
          rows: 6,
        },
        validation: [{required: true, message: "Please enter a description", trigger: "blur"}]
      },
    },
    userImages: {
      images: {
        label: "User Images",
        width: 24,
        type: "canetoad-image-upload",
        options: {
          multiple: true,
        },
        value: true,
      },
    },
    reviewStatus: {
      active: {
        type: "select",
        initialValue: "active",
        label: "Status",
        options: [
          {label: "Active", value: 1},
          {label: "Inactive", value: 0}
        ]
      }
    }
  }
}
