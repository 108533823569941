<template>
  <div class="dashboard-base">
    <component :is="appName" />
    <SalesOverview />
  </div>
</template>

<script>

import CaneToadEquip from "@/Modules/Dashboard/views/CaneToadEquip"
import SalesOverview from "./components/overview/SalesOverview.vue"
export default {
  name: "DashboardBase",
  components: {
    CaneToadEquip,
    SalesOverview
  },
  data() {
    return {
      appName: process.env.VUE_APP_BASE_PATH
    }
  }
}
</script>