<template>
  <div>
    <el-form-item
        label="Suggestion"
        :prop="fieldPath"
        :rules="validation">
      <el-input
          v-model="prompt"
          type="text" />
    </el-form-item>
    <el-button
        v-if="prompt !== '' && (inputText !== null || inputTitle !== null)"
        type="primary"
        :disabled="isLoading"
        @click.prevent="onSubmit">
      Generate Product Description
    </el-button>
    <template v-if="isLoading">
      <el-skeleton
          :rows="6"
          animated />
    </template>
    <template v-else>
      <div
          v-if="promptResult"
          class="preview"
          v-html="promptResult" />
    </template>
  </div>
</template>

<script>
import { OpenAi } from "@/Modules/Models"

export default {
  props: {
    fieldPath: {type: String, default: ""},
    options: {type: Array, default: () => []},
    inputText: {type: String, default: null},
    inputTitle: {type: String, default: null},
    validation: {type: Array, default: () => []}
  },
  data() {
    return {
      prompt: "",
      promptResult: "",
      isLoading: false
    }
  },
  mounted() {
    if (this.options.type === 'product') {
      this.prompt = 'Can you write an engaging product description (html formatted) using all the following details?';
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true
      let promptText = this.prompt
      if (this.inputTitle) {
        promptText += `\n\nTitle: ${this.inputTitle}`
      }
      if (this.inputText) {
        promptText += `\n\nDescription: ${this.inputText}`
      }

      // strip any html tags from the prompt text
      promptText = promptText.replace(/(<([^>]+)>)/gi, "")

      // strip any newlines from the prompt text
      promptText = promptText.replace(/(\r\n|\n|\r)/gm, "")

      // strip any nbsp from the prompt text
      promptText = promptText.replace(/&nbsp;/g, " ")

      const suggestionResult = await OpenAi.api().create(promptText)
      if(suggestionResult.response.data.success) {
        this.promptResult = suggestionResult.response.data.data.data.text
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .preview {
    border: 1px solid #ccc;
    padding: 1rem;
    margin-top: 1rem;
    word-break: normal;

    p {
      line-height: 1.6;
    }
  }
</style>
