import ShopCategoryManagementTableView from "./views/ShopCategoryManagementTableView"

const routes = [
  {
    path: "",
    name: "shopCategoryManagement-list-view",
    component: ShopCategoryManagementTableView
  }
]

export default routes