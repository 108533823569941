<template>
  <div class="status-column">
    <template v-if="scope.row.status">
      <el-tag
          :type="statusColor"
          disable-transitions>
        {{ scope.row.status }}
      </el-tag>
    </template>
  </div>  
</template>
<script>
export default {
  name: "OrderManagementStatusColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusColor() {
      if (this.scope.row.status.startsWith('paid')) {
        return 'success'
      } else if(this.scope.row.status === 'created') {
        return 'warning'
      }
      return 'warning'
    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    }
  }
}
</script>

<style lang="scss" scoped>

  .status-column {
    margin: 0 auto;
    .el-tag {
      min-width: 90%;
      margin: auto;
      text-align: center;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
</style>
