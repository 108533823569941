import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/appointments"

class upload extends ExtendedModel {
  static entity = "appointment"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: {
      ...apiActions,
      async fetchByDate(date) {
        const url = `${baseURL}${endpoint}/list/&filter[date]=${date}`
        return this.get(url, null)
      },
    }
  }

  get Actions() {
    return new Actions(this)
  }

  get dateIOSFormatted() {
    if (this.date) {
      return upload.convertDateForIOS(upload.formatDate(this.date))
    }
    return null
  }



}
export default upload
