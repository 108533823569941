import FeedbackManagementStatusColumn from "./FeedbackManagementStatusColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "nickname",
      label: "Nickname"
    },
    {
      prop: "quality",
      label: "Quality",
    },
    {
      prop: "installation",
      label: "Installation",
    },
    {
      prop: "satisfaction",
      label: "Satisfaction",
    },
    {
      prop: "product",
      label: "Product",
    },
    {
      prop: "active",
      label: "Status",
      Component: FeedbackManagementStatusColumn
    }
  ]

export default defaultColumns