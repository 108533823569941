<template>
  <fragment>
    <CoreScreen
        card
        class="shopCategoryManagement-wrapper"
        header="Product Category Management"
        icon="ShopCategoryManagementIcon"
        :create-button="{ label: 'Create Category', onCreate: createShopCategory }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { ShopCategory } from "@/Modules/Models"
export default {
  name: "ShopCategoryManagement",
  methods: {
    createShopCategory() {
      new ShopCategory().Actions.openShopCategoryManagementDialog()
    }
  }
}
</script>
