<template>
  <div class="CaneToadEquip-container" />
</template>

<script>
import { CarModels } from "@/Modules/Models"
export default {
  name: "CaneToadEquip",
  mounted() {
    CarModels.api().fetchAll()
  }
}

</script>

<style lang="scss" scoped>
</style>