import OrderManagementDialog from "./OrderManagementDialog/OrderManagementDialog"
import OrderEmailDialog from "./OrderEmailDialog/OrderEmailDialog"
import InvoiceEmailDialog from "./InvoiceEmailDialog/InvoiceEmailDialog"
import CreateOrderDialog from "./CreateOrderDialog/CreateOrderDialog"

export default [
  {
    name: "OrderDialog",
    Component: OrderManagementDialog,
    permissions: "OrderManagement:OrderManagement:canRead"
  },
  {
    name: "OrderEmailDialog",
    Component: OrderEmailDialog,
    permissions: "OrderManagement:OrderEmail:canRead"
  },
  {
    name: "InvoiceEmailDialog",
    Component: InvoiceEmailDialog,
    permissions: "OrderManagement:InvoiceEmail:canRead"
  },
  {
    name: "CreateOrderDialog",
    Component: CreateOrderDialog,
    permissions: "OrderManagement:CreateOrder:canRead"
  }
]