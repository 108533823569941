export default {
  fields: {
    productImportDetails: {
      skus: {
        width: 12,
        type: "select",
        label: "Import SKUs",
        placeholder: "1234",
        props: {
          multiple: true,
          filterable: true,
          allowCreate: true,
        },
        validation: [{required: true, message: "Please enter one or more SKUs", trigger: "blur"}]
      },
    }
  }
}
