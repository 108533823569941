import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class ShopCategory extends ExtendedModel {
  static entity = "ShopCategory"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get ParentCategory() {
    return this.parentId
  }

  get valueList() {
    const {id} = this
    return {label: this.title, value: id, id}
  }

}
export default ShopCategory
