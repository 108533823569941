<template>
  <fragment>
    <CoreScreen
        card
        class="feedbackManagement-wrapper"
        header="FeedbackManagement"
        icon="FeedbackManagementIcon"
        :create-button="{ label: 'Create Review', onCreate: createFeedbackManagement }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Feedback } from "@/Modules/Models"
export default {
  name: "FeedbackManagement",
  methods: {
    createFeedbackManagement() {
      new Feedback().Actions.openFeedbackDialog()
    }
  }
}
</script>
