import FeedbackManagementTableView from "./views/FeedbackManagementTableView"

const routes = [
  {
    path: "",
    name: "feedbackManagement-list-view",
    component: FeedbackManagementTableView
  }
]

export default routes