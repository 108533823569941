import BlogManagementStatusColumn from "./BlogManagementStatusColumn";
import BlogImageColumn from "./BlogImageColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      label: "Image",
      Component: BlogImageColumn
    },
    {
      prop: "title",
      label: "title"
    },
    {
      prop: "subtitle",
      label: "Subtitle"
    },
    {
      prop: "authorName",
      label: "Author",
    },
    {
      prop: "createdAt",
      label: "Created",
    },
    {
      prop: "updatedAt",
      label: "Updated",
    },
    {
      prop: "tags",
      label: "Tags",
    },
    {
      label: "Status",
      Component: BlogManagementStatusColumn
    }
  ]

export default defaultColumns