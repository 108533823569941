<template>
  <div class="forgot-password-container">
    <div class="logo-container">
      <Logo />
    </div>
    <div class="illustration-container">
      <div class="message">
        <h1>Did you forgot your password?</h1>
        <h3>No worries, we got you covered!</h3>
      </div>
    </div>


    <div class="request-password-container">
      <template v-if="status === 'success'">
        <el-alert
            type="success"
            effect="dark">
          <strong>Thank you</strong>
          <br>
          We have sent you an email with instructions to reset your password.
        </el-alert>
      </template>
      <template v-else>
        <h3>Just enter your email address and we'll send you an activation link. </h3>
        <form
            class="login-form"
            @submit.prevent="submit()">
          <div class="form-group">
            <div class="input-group">
              <div class="input">
                <label for="email">
                  Your email address
                </label>
                <input
                    id="email"
                    v-model="email"
                    type="text"
                    data-test-id="authEmail"
                    class="form-control neumorphic"
                    :class="{ disabled: loading }"
                    placeholder="john.doe@example.org">
              </div>
            </div>

            <transition name="fade">
              <template v-if="canSubmit">
                <button
                    type="submit"
                    class="button btn-confirm"
                    :class="{ disabled: loading }"
                    :disabled="loading">
                  Reset Password
                </button>
              </template>
            </transition>
          </div>
        </form>
      </template>
    </div>
    <CoreWaveyMcWaveFace
        container-background="transparent"
        wave1="#eb3514"
        wave2="#a2240e"
        wave3="#ce2e13"
        wave4="#ef4325"
        :animated="loading" />
    <div class="fill-container" />
  </div>
</template>

<script>
import { User } from "@/Modules/Models"
import Logo from "../../../components/Branding/Logo"
export default {
  name: "ForgotPassword",
  components: {
    Logo
  },
  data() {
    return {
      email: null,
      status: null,
      loading: null
    }
  },
  computed: {
    canSubmit() {
      return this.email && this.email.includes("@")
    }
  },
  methods: {
    async submit() {
      const payload = {
        email: this.email
      }
      const response = await User.api().forgotUserPassword(payload)
      if (response.response.status === 200) {
        const notificationsPayload = {
          component: "Toast",
          type: "success",
          title: "Thank you",
          message: `You have been sent an email with a link to reset your password.`,
          visible: true,
          decay: 4500
        }
        this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.forgot-password-container {
  background: var(--main-white);
  height: 100vh;
  padding: 2em 0 0 0;
  text-align: center;

  .logo-container {
    text-align: center;
    width: 8em;
    margin: 2em auto;
    img {
      width: 100%;
    }
  }

  .fill-container {
    background: var(--main-brand);
    height: calc(100vh - 30em);
  }
  .illustration-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 600px;
    margin: auto;

    .message {
      flex: 2;
    }
    .image {
      flex: 1;
      max-width: 20em;

      img {
        width: 100%;
        filter: drop-shadow(9px 9px 8px rgba(0, 0, 0, 0.2));
      }
    }
  }
  .request-password-container {
    background: #f1f3f8;
    box-shadow: var(--box-shadow-neumorphic);
    border-radius: 0.25em;
    padding: 2em;
    min-width: 20em;
    max-width: 50em;
    margin: auto;

    h2 {
      margin: 0 1em 1em 0.5em;
    }

    button {
      margin: 1em;
      border-radius: 0.25em;
      width: 100%;
    }
  }
}
</style>
