<template>
  <div class="description-column">
    <template v-if="scope.row.description">
      {{ scope.row.description | shorten(100) }}
    </template>
  </div>
</template>
<script>
export default {
  name: "ShopCategoryManagementStatusColumn",
  filters: {
    shorten(string, length) {
      // strip any html from string
      string = string.replace(/<[^>]+>/g, "")

      if (string.length > length) {
        return string.substring(0, length) + "..."
      } else {
        return string
      }
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>
