<template>
  <div class="product-thumbnail-column-container">
    <el-avatar
        shape="square"
        :size="80"
        fit="cover"
        :src="scope.row.productThumbnail" />
    <div
        v-if="scope.row.IsComboDeal === '1'"
        class="combo-deal">
      Combo Deal
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductThumbnailColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>

<style lang="scss" scoped>
.product-thumbnail-column-container {
  position: relative;
  width: 100%;
}
.combo-deal {
  position: absolute;
  top: 0;
  right: 0;
  background: #fe823f;
  border-radius: 20px;
  color: white;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}
</style>
