<template>
  <nav
      class="nav-bar"
      :class="{ minified: isMinified }">
    <a
        class="collapse"
        @click="setMinification">
      <i class="el-icon-arrow-left" />
    </a>
    <div
        class="side-navigation flex-column"
        :class="{ minified: isMinified }">
      <OperatorsBadge :is-minified="isMinified" />

      <CoreWelcomeBox v-show="!isMinified" />

      <core-magic-scrollbar>
        <NavItemGroup
            v-for="(menuItems, navGroupName) in modules"
            :key="`module-${navGroupName}`"
            class="navigation"
            :nav-group-name="navGroupName"
            :is-minified="isMinified"
            :menu-items="menuItems" />
      </core-magic-scrollbar>

      <app-footer :is-minified="isMinified" />
    </div>
  </nav>
</template>

<script>
import OperatorsBadge from "./OperatorsBadge"
import NavItemGroup from "./NavItemGroup"
import AppFooter from "./AppFooter"
import get from "lodash/get"

export default {
  name: "SideMenu",
  components: {
    OperatorsBadge,
    NavItemGroup,
    AppFooter
  },
  props: {
    setMinification: {type: Function, default: () => null},
    isMinified: {type: Boolean, default: false}
  },
  computed: {
    userPermissions() {
      return get(this.$store.state, "auth.user.permissions")
    },
    modules() {
      const allModules = this.$store.state.settings.modules
      return this.filterModulesByPermissions(allModules, this.userPermissions)
    },
    currentRegion() {
      return this.$store.state.wgBase.regions.current
    },
    currentZone() {
      return this.$store.state.wgBase.zones.current
    }
  },
  methods: {
    filterModulesByPermissions(modules, permissions) {
      const groupNames = Object.keys(modules);

      groupNames.forEach(groupName => {
        const group = modules[groupName];
        const moduleNames = Object.keys(group);

        moduleNames.forEach(moduleName => {
          if (!(moduleName in permissions)) {
            delete group[moduleName];
            return;
          }

          const modulePermissions = group[moduleName].permissions;

          if (modulePermissions) {
            const permissionKeys = Object.keys(modulePermissions);

            permissionKeys.forEach(permission => {
              if (!(permission in permissions[moduleName]) || permissions[moduleName][permission] !== true) {
                delete group[moduleName];
              }
            });
          }
        });
      });

      groupNames.forEach(groupName => {
        if (Object.keys(modules[groupName]).length === 0) {
          delete modules[groupName];
        }
      });

      return modules;
    }
  }
}
</script>

<style lang="scss" scoped>
  .minified-width {
    min-width: 5em;
    width: 5em;
  }

  .nav-bar {
    transition: width 0.1s ease-in;
    height: 100%;

    & .nav-items {
      overflow: hidden;
      &:hover {
        overflow: auto;
      }
    }

    & .side-navigation {
      position: relative;
      z-index: 100;
      height: 100vh;
      background: #FFF;
      //background: var(--main-side-navigation-dark);
      width: 17em;

      &.minified {
        width: auto;
      }
    }

    .collapse {
      position: absolute;
      right: -2.5em;
      top: 0.45em;
      z-index: 0;
      font-size: 1.2em;
      width: 1.75em;
      height: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--main-brand);
      cursor: pointer;
      border-bottom-right-radius: var(--border-radius-xs);
      border-top-right-radius: var(--border-radius-xs);

      &:hover {
        i {
          transform: scale(1.2);
          font-weight: bolder;
        }
      }
      & i {
        font-weight: bold;
        transition: transform 0.2s;
      }
    }

    &.minified {

      & .collapse:hover i {
        transform: rotate(-180deg) scale(1.2);
        font-weight: bolder;
      }

      & .collapse i {
        transform: rotate(-180deg);
      }
    }
  }
</style>
