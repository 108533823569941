<template>
  <div class="order-details">
    <div
        v-if="1 === 3"
        class="order-items">
      <h3>Order Items</h3>
      <table class="product-table">
        <thead>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Product Price</th>
        </thead>
        <tbody>
          <tr
              v-for="(item, index) in orderItems"
              :key="`item-${index}`">
            <td>{{ item.title }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ parseFloat(item.price).toFixed(2) }} AUD</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="form">
      <CoreFormBuilderV2
          :data="orderData"
          :form-config="formConfig"
          :is-loading="isLoading"
          :mode="mode"
          @onSubmit="onSubmit"
          @onCancel="onCancel" />
    </div>
  </div>
</template>

<script>
  import { Order } from "@/Modules/Models"
  import formConfig from "./formConfig"

  export default {
    name: "OrderManagementForm",
    props: {
      dialogProps: { type: Object, required: true },
      orderData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig,
        orderItems: {}
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      }
    },
    mounted() {
      this.getOrderItems()
    },
    methods: {
      async getOrderItems() {
        const result = await Order.api().getOrderItems(this.orderData.id)
        this.orderItems = result.response.data.data.data
      },
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            await Order.api().createOrder(flatFormData)

            /*
            {
    "Email": "torsten.max.schmidt@gmail.com",
    "BillPhone": "0411431061",
    "BillFirstName": "Torsten",
    "BillLastName": "Schmidt",
    "BillCompany": "DownThunder",
    "BillStreetLine1": "27 Trinette Street",
    "BillCity": "Shailer Park",
    "BillPostCode": "4128",
    "BillState": "QLD",
    "BillCountry": "Australia",
    "ShipFirstName": "Torsten",
    "ShipLastName": "Schmidt",
    "ShipPhone": "0411431061",
    "ShipCompany": "DownThunder",
    "ShipStreetLine1": "27 Trinette Street",
    "ShipCity": "Shailer Park",
    "ShipPostCode": "4128",
    "ShipState": "QLD",
    "ShipCountry": "Australia",
    "OrderLine": {
        "products": {
            "1197": {
                "product": {
                    "$id": "1197",
                    "id": "1197",
                    "_saved": true,
                    "_delete": false,
                    "_loading": false,
                    "ID": null,
                    "AvailableSellQuantity": "3",
                    "Brand": "CANE TOAD EQUIP ",
                    "Categories": [
                        "14"
                    ],
                    "CostPrice": "511.36",
                    "DateAdded": "2024-07-15 00:47:03",
                    "DefaultPrice": "979.00",
                    "PreOrderEta": "2024-02-10",
                    "Description": "<p>Revolution Bullbar for Ford Ranger PX2 PX3 Airbag Compatible ADR Matte Black Powder Coat Steel</p><p><br></p><p><em>Revolution Bull Bar for&nbsp;Ford Ranger PX MK2 MK3 Model,&nbsp;&nbsp;</em><em style=\"color: rgb(230, 0, 0);\">(need cut extra 2 sensor holes on PX3 model with techpack )</em></p><p><br></p><p><br></p><p><em><u>For MK2 and MK3 No bumper bar cut required, Easy installation</u></em></p><p><br></p><p>- Manuctured using heavy duty steel construction</p><p>- Provide strong and stylish front end protection</p><p>- Airbag compatible</p><p>- Finished in high-grade matte black powder coat for rust protection and&nbsp;durability.</p><p>-&nbsp;Extra heavy duty steel body</p><p>- Underbody skid protection plate</p><p>- Full mounting, installation kit</p><p><br></p><p><em><u>Package includes:</u></em></p><p><br></p><p>- Pair of Led Fog light</p><p>- Underbody skid&nbsp;protection&nbsp;plates</p><p>- Full mounting / installation kit</p><p>- Install Instruction</p><h1><br></h1>",
                    "Features": "<ul><li>\tCome with 3 Ton Rated Recovery Points on both side. </li><li>    Led fog lights and indicator included</li><li>    Winch compatible</li><li>    Airbag compatible</li><li>    Underbody skid protection plate included</li><li>    Heavy duty steel construction mounting cradle </li><li>    Finished in high-grade matte black powder coat for rust protection and&nbsp;durability.</li><li><em>    </em>No bumper cut required, Easy installation</li></ul><p><br></p><p><strong>Professional install highly recommended.</strong></p><p><strong>DIY level:  medium to high.  </strong></p><p><br></p>",
                    "ImageUrl": null,
                    "ImageList": [
                        "ABBARSABT7PX23R4CTE.jpg",
                        "ABBARSABT7PX23R46.jpg",
                        "ABBARSABT7PX23R45.jpg",
                        "CoverPhotowildplanet.JPG",
                        "ABBARSABT7PX23R47.jpg",
                        "IMG2346.JPG",
                        "IMG2347.JPG"
                    ],
                    "InventoryId": "0",
                    "ItemSpecifics": false,
                    "Name": "Revolution Bullbar for Ford Ranger PX2 PX3 Airbag Compatible ADR Matte Black Powder Coat Steel",
                    "ShortName": "Revolution Bullbar for Ford Ranger PX2 PX3",
                    "RRP": "1499",
                    "SKU": "U-BBAR-SAB-T7PX23-R4",
                    "ShortDescription": "",
                    "Specifications": "",
                    "Subtitle": "ADR & Airbag Compatible, Matte Black Powder Coat Steel",
                    "WarehouseQuantity": null,
                    "Warranty": null,
                    "CarModels": [
                        [
                            "1",
                            "1",
                            "2"
                        ],
                        [
                            "1",
                            "1",
                            "3"
                        ]
                    ],
                    "PackageLength": "1.550",
                    "PackageWidth": "0.600",
                    "PackageHeight": "0.600",
                    "PackageWeight": "100.0000",
                    "ProductLength": "0.000",
                    "ProductWidth": "0.000",
                    "ProductHeight": "0.000",
                    "ProductWeight": "40",
                    "ProductWarrantyYears": "2",
                    "Youtube": "",
                    "IsComboDeal": "0",
                    "ComboDealDetails": "",
                    "SpecLoadRatingOnroadStatic": "",
                    "SpecLoadRatingOnroadDynamic": "",
                    "SpecLoadRatingOffroad": "",
                    "SpecMaterial": "",
                    "active": "1"
                },
                "quantity": 1
            },
            "1200": {
                "product": {
                    "$id": "1200",
                    "id": "1200",
                    "_saved": true,
                    "_delete": false,
                    "_loading": false,
                    "ID": null,
                    "AvailableSellQuantity": "3",
                    "Brand": "CANE TOAD EQUIP ",
                    "Categories": [
                        "14"
                    ],
                    "CostPrice": "511.36",
                    "DateAdded": "2024-08-12 00:50:52",
                    "DefaultPrice": "1049.95",
                    "PreOrderEta": "0000-00-00",
                    "Description": "<p><strong>Revolution G3 Bullbar fits Navara NP300 D23 2015-2019 Pre Facelift Model Airbag ADR Compliant Matte Black</strong></p><p><strong>&nbsp;</strong></p><ul><li><em><u>Easy installation</u></em></li><li>- Manufactured using heavy duty steel construction</li><li>- Provide strong and stylish front end protection</li><li>- Airbag compatible</li><li>- Finished in high-grade matte black powder coat for rust protection and&nbsp;durability.</li><li>-&nbsp;Extra heavy duty steel body</li><li>- Underbody skid protection plate</li><li>- Full mounting, installation kit</li></ul><p><br></p><p><strong><em>Package includes:</em></strong></p><ul><li>- a Pair of Led Fog light</li><li>- Underbody skid&nbsp;protection&nbsp;plates</li><li>- Full mounting / installation kit</li></ul><p><br></p>",
                    "Features": "<ul><li>\tCome with 2 Ton Rated Recovery Points on both side. </li><li>    Led fog lights and indicator included</li><li>    Winch compatible</li><li>    Airbag compatible</li><li>    Underbody skid protection plate included</li><li>    Heavy duty steel construction mounting cradle </li><li>    Finished in high-grade matte black powder coat for rust protection and&nbsp;durability.</li><li><em>    </em>No bumper cut required, Easy installation</li></ul><p><br></p><p><strong>Professional install highly recommended.</strong></p><p><strong>DIY level:  medium to high.  </strong></p>",
                    "ImageUrl": null,
                    "ImageList": [
                        "aff047a00ffc44768be499890ffbbd4e.jpg",
                        "d0a85b6a2fde4769a0efce52aa8e7531.jpg",
                        "IMG5637-1724478543.JPG",
                        "IMG5638-1724478543.JPG",
                        "IMG5651-1724478543.JPG"
                    ],
                    "InventoryId": "0",
                    "ItemSpecifics": false,
                    "Name": "Revolution G3 Bullbar fitsNavara NP300 D23 2015-2019 Airbag ADR Compatible Matte Black",
                    "ShortName": "G3 Revolution Bullbar for Navara NP300 2015-2019 Pre Face Lift Model",
                    "RRP": "1499",
                    "SKU": "U-Bbar-S-NP300-R3",
                    "ShortDescription": "",
                    "Specifications": "",
                    "Subtitle": "ADR & Airbag Compatible, Matte Black Powder Coat Steel",
                    "WarehouseQuantity": null,
                    "Warranty": null,
                    "CarModels": [
                        [
                            "7",
                            "11",
                            "34"
                        ]
                    ],
                    "PackageLength": "1.65",
                    "PackageWidth": "0.5",
                    "PackageHeight": "0.6",
                    "PackageWeight": "200",
                    "ProductLength": "1.55",
                    "ProductWidth": "0.4",
                    "ProductHeight": "0.6",
                    "ProductWeight": "40",
                    "ProductWarrantyYears": "2",
                    "Youtube": "",
                    "IsComboDeal": "0",
                    "ComboDealDetails": "",
                    "SpecLoadRatingOnroadStatic": "",
                    "SpecLoadRatingOnroadDynamic": "",
                    "SpecLoadRatingOffroad": "",
                    "SpecMaterial": "",
                    "active": "1"
                },
                "quantity": 1
            }
        },
        "total": 2028.95
    },
    "ShippingOption": "pickup",
    "ShippingTotal": "100",
    "GrandTotal": "2128.95",
    "DeliveryInstruction": "Deliver to door"
}
             */
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your OrderManagement details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .order-items {
    padding: 0 1em;
    .product-table {
      width: 100%;
      border: 1px solid var(--main-border-color);

      thead {
        background: var(--main-light-blue);
      }
      tbody {
        tr {
          &:nth-child(even) {
          td {
              background: var(--main-light-grey)
            }
          }
        }
        td {
          padding: 0.25em 0.5em;
        }
      }
    }
  }
</style>