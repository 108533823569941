import autobind from "auto-bind"

class BulkActions {
  constructor(entity) {
    this.entity = entity

    autobind(this)
  }

  async bulkActivate(records) {
    const { store } = this.entity

    const ids = records.map(({ id }) => id)

    const response = await this.entity.Api.bulkActivate(ids)

    let notificationsPayload

    if (response) {
      notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: `All selected products have been activated`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error activating products",
        message: `Sorry, your Bulk Activation action has not been successful.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    // Reload products that have been updated
    this.entity.Api.fetchByIds(ids)
  }

  async bulkDeactivate(records) {
    const { store } = this.entity

    const ids = records.map(({ id }) => id)

    const response = await this.entity.Api.bulkDeactivate(ids)

    let notificationsPayload

    if (response) {
      notificationsPayload = {
        component: "Toast",
        type: "success",
        title: "Thank you",
        message: `All selected products have been deactivated`,
        visible: true,
        decay: 0
      }
    } else {
      notificationsPayload = {
        component: "Toast",
        type: "error",
        title: "Error deactivating products",
        message: `Sorry, your Bulk Deactivation action has not been successful.`,
        visible: true,
        decay: 3500
      }
    }
    store.dispatch("core/notifications/addToNotifications", notificationsPayload)
    // Reload products that have been updated
    this.entity.Api.fetchByIds(ids)
  }

  get list() {
    return [
      {
        visible: true,
        onClick: this.bulkActivate,
        label: "Activate products",
        icon: "el-icon-circle-check",
        buttonType: "success"
      },
      {
        visible: true,
        onClick: this.bulkDeactivate,
        label: "Deactivate products",
        icon: "el-icon-circle-close",
        buttonType: "danger"
      }
    ]
  }
}

export default BulkActions
