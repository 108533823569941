export default {
  fields: {
    personalDetails: {
      title: {
        type: "text",
        value: null,
        label: "Category Title",
        placeholder: "ie. Bullbars",
        validation: [{required: true, message: "Please enter a title", trigger: "blur"}]
      },
      parentId: {
        type: "entity-select",
        props: {
          entity: "ShopCategory",
          filterable: true,
          clearable: true,
          multiple: false,
        },
        label: "Parent Category",
        options: [],
      },
      description: {
        width: 24,
        type: "html-editor",
        label: "Product Description",
      },
      backgroundImage: {
        label: "Category Image",
        width: 24,
        type: "canetoad-image-upload",
        options: {
          multiple: false,
        },
        value: true,
      },
      tags: {
        type: 'select',
        label: 'Tags',
        props: {
          multiple: true,
          filterable: true,
          allowCreate: true,
        }
      },
      active: {
        type: "select",
        initialValue: "active",
        label: "Status",
        options: [
          {label: "Active", value: "1"},
          {label: "Inactive", value: "0"},
        ]
      }
    }
  }
}
