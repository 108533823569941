export default {
  fields: {
    emailDetails: {
      fullName: {
        width: 24,
        type: "text",
        label: "Recipient Name",
        props: {
          disabled: true
        }
      },
      emailTo: {
        width: 8,
        type: "email",
        label: "Email To",
        placeholder: "john.doe@example.org",
        validation: [{required: true, message: "Please enter an email address", trigger: "blur"}]
      },
      emailFrom: {
        width: 8,
        type: "email",
        label: "Email From",
        placeholder: "sales@canetoadequip.com.au",
        validation: [{required: true, message: "Please enter an email address", trigger: "blur"}]
      },
      emailBCCTo: {
        width: 8,
        type: "email",
        label: "Email BCC To",
        placeholder: "john.doe@example.org",
      },
      emailSubject: {
        type: "text",
        width: 24,
        label: "Subject",
        placeholder: "Canetoad Equip Order No. 1234",
        validation: [{required: true, message: "Please enter a subject", trigger: "blur"}]
      },
      attachPDF: {
        width: 24,
        type: "checkbox",
        label: "Attach Tax Invoice as PDF to Email",
      }
    }
  }
}
