<template>
  <div>
    <core-data-table-v2
        :model="feedbackModel"
        :columns="columns"
        table-name="feedbackManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Feedback } from "@/Modules/Models"
import columns from "../components/FeedbackManagementTableView/columns"
export default {
  name: "FeedbackManagementTableView",
  computed: {
    feedbackModel() {
      return Feedback
    },
    columns() {
      return columns
    }
  }
}
</script>
