<template>
  <div>
    <core-data-table-v2
        :model="productModel"
        :columns="columns"
        :searches-and-filters="filters"
        table-name="productManagement-management"
        v-on="$listeners" />
  </div>
</template>

<script>
import { CaneToadProduct } from "@/Modules/Models"
import { CarModels } from "@/Modules/Models"
import columns from "../components/CaneToadProductManagementTableView/columns"
import filterConfig from "../components/CaneToadProductManagementTableView/filterConfig"
export default {
  name: "CaneToadProductManagementTableView",
  data() {
    return {
      isLoading: false,
      filters: filterConfig
    }
  },
  computed: {
    productModel() {
      return CaneToadProduct
    },
    columns() {
      return columns
    }
  },
  mounted() {
    this.getCarModels()
  },
  methods: {
    async getCarModels() {
      this.isLoading = true
      const response = await CarModels.api().fetchAll()
      this.$store.dispatch("CaneToadProductManagement/updateCarModels", response.response.data.data)
      this.isLoading = false
    }
  }
}
</script>
