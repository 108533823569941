<template>
  <div class="preview-container">
    <div class="product-card-container">
      <div class="product-container__inner">
        <div class="image-container">
          <img
              v-if="mainImage"
              :src="mainImage"
              alt="Product Image">
        </div>
        <div class="description-container">
          <div class="product-title">
            <h3 class="product-title-tag">
              <div
                  v-if="brand"
                  class="product-brand">
                {{ brand }}
              </div>
              <span
                  class="title"
                  v-html="title" />
            </h3>

            <span
                v-if="shortName && subtitle"
                class="product-subtitle">{{ subtitle }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    fieldPath: {type: String, default: ""},
    options: {type: Array, default: () => []},
    name: {type: String, default: null},
    shortName: {type: String, default: null},
    subtitle: {type: String, default: null},
    brand: { type: String, default: null },
    images: {type: Array, default: () => []}
  },
  computed: {

    title() {
      let newTitle = "";
      if (this.shortName) {
        newTitle = this.shortName;
      } else {
        newTitle = this.name;
      }
      return newTitle;
    },
    mainImage() {
      let image = null
      if(this.images.length > 0) {
        image = this.images[0]

        return `https://api.canetoadequip.com.au/pic/600x0/media/productImages/${image}`
      }
      return null
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-container {
  background: #fbf5f3;
  padding: 2rem;
}
.product-card-container {
  position: relative;
  flex: 1;
  background: white;
  border-radius: 8px;
  width: 320px;
  flex-shrink: 0;
  padding: 18px;
  margin: auto;

  img {
    width: 100%;
  }

  &.selected {
    box-shadow: 1px 1px 10px var(--color-price-text);
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.02) 1px 0.8px 1.1px 0px, rgba(0, 0, 0, 0.027) 2.5px 2px 2.7px 0px, rgba(0, 0, 0, 0.035) 4.6px 3.8px 5px 0px, rgba(0, 0, 0, 0.043) 8.3px 6.7px 8.9px 0px, rgba(0, 0, 0, 0.05) 15.5px 12.5px 15.4px 0px, rgba(0, 0, 0, 0.07) 37px 30px 40px 0px;
  }
  h3 {
    margin: 0;
  }
  .product-brand {
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
  }
  .title {
    font-size: 14px;
    display: inline-block;
    line-height: 1.5;
    word-break: normal;
  }
  .product-subtitle {
    font-size: 12px;
    color: rgb(158, 158, 158);
  }
}
</style>
