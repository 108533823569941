import CaneToadProductManagement from "./CaneToadProductManagement"
import CaneToadProductManagementIcon from "./components/CaneToadProductManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"
import store from "./store"

const ProductManagementModule = {
  moduleGrouping: "Inventory Management",
  name: "CaneToadProductManagement",
  menuName: "Product Management",
  component: CaneToadProductManagement,
  path: "ProductManagement",
  icon:CaneToadProductManagementIcon,
  iconTitle: "Manage Products in your catalog",
  iconDescription: "Manage Products here",
  enabled: true,
  features,
  store,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("CaneToadProductManagement", CaneToadProductManagement)
    Vue.component("CaneToadProductManagementIcon", CaneToadProductManagementIcon)
  }
}
export default ProductManagementModule
