<template>
  <div class="add-to-cart-column">
    <template v-if="scope.row.active">
      <el-button
          size="mini"
          @click.prevent="onAddToCartClick">
        Add to Cart
      </el-button>
    </template>
  </div>
</template>
<script>
export default {
  name: "CaneToadAddToCartColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    status() {
      if (this.scope.row.deceased === "1") {
        return "deceased"
      } else if (this.scope.row.active === "0") {
        return "inactive"
      } else {
        return "active"
      }
    }
  },
  methods: {
    filterStatus(value, row) {
      return row.active === value
    },
    onAddToCartClick() {
      this.$emit("onAddToCartClick", this.scope.row)
    }
  }
}
</script>
