import OrderManagementStatusColumn from "./OrderManagementStatusColumn";
import OrderManagementPaymentTypeColumn
  from "./OrderManagementPaymentTypeColumn.vue";
const defaultColumns = [
    {
      prop: "orderID",
      label: "Order ID"
    },
    {
      prop: "createdDateTime",
      label: "Created"
    },
    {
      prop: "updatedDateTime",
      label: "Updated"
    },
    {
      prop: 'username',
      label: 'Username',
    },
    {
      prop: "paymentType",
      label: "Payment Type",
    },
    {
      label: "Payment Type",
      Component: OrderManagementPaymentTypeColumn,
    },
    {
      prop: "priceCostAud",
      label: "Cost",
    },
    {
      prop: "pricePaidAud",
      label: "Paid",
    },
    {
      label: "Status",
      Component: OrderManagementStatusColumn
    }
]

export default defaultColumns