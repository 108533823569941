<template>
  <fragment>
    <CoreScreen
        card
        class="systemSettings-wrapper"
        header="System Settings"
        icon="SystemSettingsIcon">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Settings } from "@/Modules/Models"
export default {
  name: "SystemSettings",
  methods: {
    createSystemSettings() {
      new Settings().Actions.openSettingsDialog()
    }
  }
}
</script>
