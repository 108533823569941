<template>
  <div
      id="app"
      :style="rootColorVariables">
    <router-view />
  </div>
</template>

<script>
import get from "lodash/get"

export default {
  name: "App",
  computed: {
    isAllowedPath() {
      const allowedPaths = []
      allowedPaths.push("dashboard", "login", '/')
      // merge with this.allowedPaths
      if (this.allowedPaths) {
        allowedPaths.push(...this.allowedPaths)
      }
      const currentPath = this.$route.path
      let path = currentPath
      if (currentPath !== '/') {
        path = currentPath.replace(/\//g, "")
      }
      return allowedPaths.includes(path)
    },
    userPermissions() {
      return get(this.$store.state, "auth.user.permissions")
    },

    brandColor() {
      return this.$store.state.settings.app.brandColor
    },
    rootColorVariables() {
      return `--brand-hue: ${this.brandColor.h}; --brand-sat: ${this.brandColor.s}; --brand-light :${this.brandColor.l};`
    },
    allowedPaths() {
      const allModules = this.$store.state.settings.modules
      return this.getModulesWithPermissions(allModules, this.userPermissions)
    },
  },
  watch: {
    $route() {
      if (!this.isAllowedPath) {
        this.$router.push({ path: '/' })
      }
    }
  },
  methods: {
    getModulesWithPermissions(modules, permissions) {
      if (modules && permissions) {
        const modulePaths = [];

        // Iterate through each group
        const groupNames = Object.keys(modules);

        groupNames.forEach(groupName => {
          const group = modules[groupName];
          const moduleNames = Object.keys(group);

          moduleNames.forEach(moduleName => {
            if ((moduleName in permissions)) {
              modulePaths.push(modules[groupName][moduleName].path);
            }

            const modulePermissions = group[moduleName].permissions;

            if (modulePermissions) {
              const permissionKeys = Object.keys(modulePermissions);

              permissionKeys.forEach(permission => {
                if (!(permission in permissions[moduleName]) || permissions[moduleName][permission] !== true) {
                  delete group[moduleName];
                }
              });
            }
          });
        });

        return modulePaths;
      }
    }
  }
}
</script>
