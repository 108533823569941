<template>
  <div class="status-column">
    <template v-if="scope.row.articleThumbnail">
      <el-avatar
          shape="square"
          :size="80"
          fit="cover"
          :src="scope.row.articleThumbnail" />
    </template>
  </div>
</template>
<script>
export default {
  name: "BlogManagementImageColumn",
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
}
</script>
