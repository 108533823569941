<template>
  <div class="password-input-container">
    <div class="password-input-elements">
      <el-form-item
          :prop="fieldPath"
          :rules="validation">
        <el-input
            v-model="password"
            type="password"
            placeholder="Enter Password"
            show-password
            @blur.prevent="checkPassword" />
      </el-form-item>

      <el-form-item
          :prop="fieldPath"
          :rules="validation">
        <el-input
            v-model="confirmPassword"
            placeholder="Confirm Password"
            type="password"
            show-password
            @input="checkPassword" />
      </el-form-item>
    </div>
    <div
        v-if="passwordError"
        class="show-password-error">
      <el-alert
          v-if="passwordError"
          title="Passwords do not match"
          type="error"
          show-icon />
    </div>
    <div
        v-if="passwordSuccess"
        class="show-password-success">
      <el-alert
          v-if="passwordSuccess"
          title="Passwords match"
          type="success"
          show-icon />
    </div>
  </div>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"


export default {
  name: "PasswordInput",
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordError: null,
      passwordSuccess: null,
    }
  },
  computed: {
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    }
  },
  methods: {
    checkPassword() {
      if (this.password !== '' && this.confirmPassword !== '') {
        if (this.password !== this.confirmPassword) {
          this.passwordError = true
          this.passwordSuccess = false
        } else {
          this.passwordError = false
          this.passwordSuccess = true
          this.$emit('input', this.password)
        }
      } else {
        this.passwordError = false
        this.passwordSuccess = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.password-input-elements {
  display: flex;
  gap: 1rem;

  .el-form-item {
    flex: 1;
  }
}
</style>
