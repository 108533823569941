import ShopCategoryManagement from "./ShopCategoryManagement"
import ShopCategoryManagementIcon from "./components/ShopCategoryManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const ShopCategoryManagementModule = {
  moduleGrouping: "Inventory Management",
  name: "ShopCategoryManagement",
  menuName: "Product Categories",
  component: ShopCategoryManagement,
  path: "ShopCategoryManagement",
  icon: ShopCategoryManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View ShopCategoryManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("ShopCategoryManagement", ShopCategoryManagement)
    Vue.component("ShopCategoryManagementIcon", ShopCategoryManagementIcon)
  }
}
export default ShopCategoryManagementModule
