import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    productId: null,
    nickname: null,
    email: null,
    rating: null,
    images: null,
    quality: null,
    installation: null,
    satisfaction: null,
    description: null,
    visibility: null,
    active: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition