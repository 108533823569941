<template>
  <div class="order-details">
    <div
        v-if="1 === 3"
        class="order-items">
      <h3>Order Items</h3>
      <table class="product-table">
        <thead>
          <th>Product Name</th>
          <th>Quantity</th>
          <th>Product Price</th>
        </thead>
        <tbody>
          <tr
              v-for="(item, index) in orderItems"
              :key="`item-${index}`">
            <td>{{ item.title }}</td>
            <td>{{ item.quantity }}</td>
            <td>{{ parseFloat(item.price).toFixed(2) }} AUD</td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <div class="form">
      <CoreFormBuilderV2
          :data="orderData"
          :form-config="formConfig"
          :is-loading="isLoading"
          :mode="mode"
          @onSubmit="onSubmit"
          @onCancel="onCancel" />
    </div>
  </div>
</template>

<script>
  import { Order } from "@/Modules/Models"
  import formConfig from "./formConfig"

  export default {
    name: "OrderManagementForm",
    props: {
      dialogProps: { type: Object, required: true },
      orderData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig,
        orderItems: {}
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      }
    },
    mounted() {
      this.getOrderItems()
    },
    methods: {
      async getOrderItems() {
        const result = await Order.api().getOrderItems(this.orderData.id)
        this.orderItems = result.response.data.data.data
      },
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            await Order.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.orderData.id
            await Order.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your OrderManagement details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .order-items {
    padding: 0 1em;
    .product-table {
      width: 100%;
      border: 1px solid var(--main-border-color);

      thead {
        background: var(--main-light-blue);
      }
      tbody {
        tr {
          &:nth-child(even) {
          td {
              background: var(--main-light-grey)
            }
          }
        }
        td {
          padding: 0.25em 0.5em;
        }
      }
    }
  }
</style>