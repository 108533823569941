<template>
  <div>
    <core-data-table-v2
        :model="userModel"
        :columns="columns"
        table-name="user-management"
        v-on="$listeners" />
  </div>
</template>

<script>
  import { User } from "@/Modules/Models"
  import columns from "../components/UserManagementTableView/columns"

  export default {
    name: "PatientTableView",
    data() {
      return {
        isLoading: false
      }
    },
    computed: {
      userModel() {
        return User
      },
      columns() {
        return columns
      }
    }
  }
</script>
